<template>
    <b-table class="m-0" v-bind:fields="tableFields" v-bind:items="items" thead-class="u19 text-uppercase" responseive hover />
</template>

<script>
import { calculateAge } from '@/utils/dateUtils';

export default {
    name: 'under19Table',
    props: {
        playerData: {
            type: Array,
            required: true
        },
        tournamentStartDate: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            tableFields: [
                {
                    key: 'displayName',
                    label: 'Name',
                },
                {
                    key: 'teamAliasName',
                    label: 'Team',
                },
                {
                    key: 'dateOfBirth',
                    label: 'Date of Birth (Age)',
                },
            ]
        }
    },
    methods: {
        formatData() {
            return this.playerData.map((player) => 
                ({...player, dateOfBirth: this.formatDateOfBirth(player.dateOfBirth)})
            );
        },
        formatDateOfBirth(dateOfBirth) {
            const dateObj = new Date(dateOfBirth);
            const formattedDate = this.$fd(dateObj, 'date');
            const age = calculateAge(dateObj, new Date(this.tournamentStartDate));

            return `${formattedDate} (${age})`;
        }
    },
    computed: {
        items() {
            return this.formatData();
        }
    },
}
</script>
