<template>
    <b-table-simple
        class="m-0 personnelTable printTable"
        responsive hover
    >
    <b-thead>
        <b-tr class="headerDisplay">
            <b-th colspan="2" class="text-uppercase">Match Officials</b-th>
        </b-tr>
    </b-thead>
    <b-tbody>
        <b-tr v-for="person in matchOfficials" v-bind:key="person.name">
            <b-td class="positionDisplay" >
                {{ person.position }}
            </b-td>
            <b-td>
                {{ person.name }}
            </b-td>
        </b-tr>
    </b-tbody>
    </b-table-simple>
</template>

<script>

export default {
    name: 'matchOfficialsTable7s',
    props: {
        matchOfficials: {
            type: Array,
            required: true
        }
    },
}
</script>

<style lang="scss" scoped>
    .positionDisplay {
        text-align: end;
    }
    .headerDisplay {
        text-align: center;
    }
</style>
