<template>
    <div class="pageContent">
        <wrapper
            v-bind:title="$t('editRankingTeam')"
            v-bind:waitFor="apiData.rankingUnion"
            v-bind:waitForRetry="reloadRankingUnion"
            v-on:submit="updateUnion"
            mainTitle
            isForm
            doubleButtons
        >
            <template v-slot:buttons>
                <actionButton
                    v-bind:to="toCalculationList"
                    v-bind:loadingWhile="apiData.unionUpdate"
                >
                    {{ $t('cancel') }}
                </actionButton>
                <actionButton
                    type="submit"
                    v-bind:loadingWhile="apiData.unionUpdate"
                >
                    {{ $t('save') }}
                </actionButton>
            </template>
            <template v-slot:header v-if="error">
                <validations v-bind:errors="error.getErrorMessages($t('requestError'))" v-if="error"/>
            </template>
            <template v-slot>
                <createEditCalculationRankingUnion
                    v-bind:model="rankingUnion"
                    v-bind:errors="error ? error.getErrorIds() : undefined"
                />
            </template>
        </wrapper>
    </div>
</template>

<script>
import rankingBase from '@/views/administration/rankings/base.vue';
import validations from '@/components/validations.vue';
import createEditCalculationRankingUnion from '@/components/forms/createEditCalculationRankingUnion.vue';

import RankingUnionModel from '@/models/rankingUnion.js';

export default {
    name: 'administrationCalculationUnionEdit',
    extends: rankingBase,
    components: {
        validations,
        createEditCalculationRankingUnion
    },
    props: {
        dataRankingUnionId: {
            type: Number,
            mandatory: true,
            default: null,
        },
    },
    data(){
        return {
            apiData: {
                rankingUnion: this.loadRankingUnion(),
                rankingUnionUpdate: null,
            },
            rankingUnion: new RankingUnionModel(),
            error: null,
        };
    },
    computed: {
        toCalculationList(){
            return { name: 'administrationRankingCalculationsIndex' };
        },
    },
    methods: {
        loadRankingUnion(){
            return this.$api.call.rankings.rankingUnionGetByTeamId(this.dataRankingUnionId).then(data => {
                this.rankingUnion = RankingUnionModel.from(data);
            });
        },
        reloadRankingUnion(){
            this.apiData.rankingUnion = this.loadRankingUnion();
        },
        updateUnion() {
            this.rankingUnion.sport = this.sportId;

            this.apiData.rankingUnionUpdate = this.$api.call.rankings.rankingUnionUpdateById(this.dataRankingUnionId, this.rankingUnion.toJSON()).then(result => {
                this.error = null;
                this.$router.push(this.toCalculationList);
            }).catch(error => {
                if(this.$log){
                    this.$log.warn('ranking union update failed', error);
                }

                //show validation errors
                this.error = error;
            });
        },
    },
}
</script>
