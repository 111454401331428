<template>
    <b-table
        class="m-0 personnelTable minWidthNoWrap"
        v-bind:class="computedClasses"
        responsive hover
        thead-class="p-t-centre t-centre"
        v-bind:tbody-tr-class="'noBorder personnelRow'"
        v-bind:fields="[
            {key: 'result', label: 'Results', thClass: 'text-uppercase'}
        ]"
        v-bind:items="items"
    />
</template>
<script>

export default {
    name: 'resultTable7s',
    props: {
        items: {
            type: Array,
            required: true
        },
        classes: {
            type: Object,
            default: () => ({})
        },
    },
    computed: {
        computedClasses() {
            return {
                ...this.classes
            };
        }
    },
}
</script>
