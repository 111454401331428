<template>
    <b-table
        class="m-0 teamsheetTable"
        thead-class="text-uppercase"
        v-bind:fields="tableFields"
        v-bind:tbody-tr-class="'teamsheetRow'"
        v-bind:items="items" responsive hover
    >
        <template #cell(number)="data">
            {{ data.item.number }}
        </template>
        <template #cell(name)="data">
            <span class="no-wrap">{{ data.item.name }}</span>
        </template>
        <template #cell(dateOfBirth)="data">
            <span v-bind:class="{'no-wrap': true, 'text-danger': data.item.isUnderage}">
                {{ data.item.dateOfBirth }}
            </span>
        </template>
        <template #cell(frontRowEligible)="data">
            {{ data.item.frontRowEligible }}
        </template>
    </b-table>
</template>

<script>
import { isUnderage, calculateAge } from '@/utils/dateUtils';

export default {
    name: 'teamsheetSummaryTable',
    components: {},
    props: {
        players: {
            type: Array,
            required: true,
        },
        matchKickOffDate: {
            type: Date,
            required: true
        }
    },
    data() {
        return {
            tableFields: [
                {
                    key: 'number',
                    label: '',
                    class: 'text-center',
                },
                {
                    key: 'name',
                    label: 'Name',
                    tdClass: 'match-teamsheet-name',
                },
                {
                    key: 'dateOfBirth',
                    label: 'DOB (Age)',
                    tdClass: '',
                },
                {
                    key: 'weight',
                    label: 'KG',
                },
                {
                    key: 'height',
                    label: 'CM',
                },
                {
                    key: 'frontRowEligible',
                    label: 'FRE',
                },
            ],
        }
    },
    methods: {
        formatTableData(players) {
            return players.map(player => ({
                number: this.getDisplayNumber(player),
                name: this.formatName(player),
                dateOfBirth: this.formatDateOfBirth(player),
                isUnderage: isUnderage(player.dateOfBirth, 19, this.matchKickOffDate),
                height: player.height,
                weight: player.weight,
                frontRowEligible: this.getFrontRowEligibility(player),
            })).sort((a, b) => a.number - b.number);
        },
        formatName(player) {
            if (player.getPlayerAttribute('isCaptain')) {
                return player.displayName + ' (C)';
            }

            return player.displayName;
        },
        formatDateOfBirth(person) {
            if (person.dateOfBirth == null) {
                return null;
            }
            const formattedDateOfBirth = this.$fd(person.dateOfBirth, 'date');
            const formattedAge = calculateAge(person.dateOfBirth, this.matchKickOffDate);

            return `${formattedDateOfBirth} (${formattedAge})`;
        },
        getDisplayNumber(person) {
            if (person && (person.getPlayerAttribute('shirtNumber') || person.getPlayerAttribute('squadNumber'))) {
                const number = (person.getPlayerAttribute('shirtNumber') || person.getPlayerAttribute('squadNumber') || '');
                if (!isNaN(Number(number))) {
                    return parseInt(number);
                } else {
                    return number;
                }
            }

            if (person?.number) {
                return person.number;
            }

            return '';
        },
        getFrontRowEligibility(player) {
            let frontRowEligibilities = '';

            const isTightHead = player.getPlayerAttribute('isTighthead') || player.isTighthead || false;
            const isHooker = player.getPlayerAttribute('isHooker') || player.isHooker || false;
            const isLoosehead = player.getPlayerAttribute('isLoosehead') || player.isLoosehead || false;

            const values = [isTightHead, isHooker, isLoosehead];
            const labels = ['TH', 'HK', 'LH'];
            const seperator = ', ';

            for(let i = 0; i < values.length; i++){
                if(values[i]){
                    frontRowEligibilities += labels[i] + seperator;
                }
            }

            if(frontRowEligibilities.length){
                frontRowEligibilities = frontRowEligibilities.substring(0, frontRowEligibilities.length - seperator.length);
            }

            return frontRowEligibilities;
        },
    },

    computed: {
        items() {
            return this.formatTableData(this.players);
        }
    }
}
</script>
