<template>
    <div class="pageContent">
        <actionButton
            variant="outline-primary"
            class="btn-flat mb-5"
            v-bind:to="backToMatchDetailsLink"
        >
            <icon type="arrow-left" class="align-middle"/> {{ $t('backToPreviousScreen') }}
        </actionButton>

        <matchReportsHeader
            v-bind:waitFor="apiData.teamsheets"
            v-bind:waitForRetry="reloadTeamsheets"
            v-bind:title="$t('matchTeamsheetSummary')"
            v-bind:data="teamsheets"
            v-bind:teamOneHeadCoach="teamOneHeadCoach"
            v-bind:teamTwoHeadCoach="teamTwoHeadCoach"
            v-bind:matchOfficials="matchOfficials"
        />

        <wrapper
            v-bind:waitFor="apiData.teamsheets"
            v-bind:waitForRetry="reloadTeamsheets"
            noPadding noLine noShadow waitSilent
        >
            <h2 class="text-center mb-5 mt-5">{{ $t('lineup') }}</h2>
            <div class="d-flex flex-row gap-15">
                <teamsheetSummaryTable v-bind:players="team1Lineup" v-bind:matchKickOffDate="matchKickOffDate" />
                <teamsheetSummaryTable v-bind:players="team2Lineup" v-bind:matchKickOffDate="matchKickOffDate" />
            </div>
            <h2 class="text-center mb-5 mt-5">{{ $t('subs') }}</h2>
            <div class="d-flex flex-row gap-15">
                <teamsheetSummaryTable v-bind:players="team1Subs" v-bind:matchKickOffDate="matchKickOffDate" />
                <teamsheetSummaryTable v-bind:players="team2Subs" v-bind:matchKickOffDate="matchKickOffDate" />
            </div>
        </wrapper>
    </div>
</template>

<script>
import base from '@/views/base.vue';
import matchReportsHeader from '@/components/matchReportsHeader.vue';
import teamsheetSummaryTable from '@/components/tables/TeamsheetSummaryTable.vue';
import MatchTeamsheetModel from '@/models/matchTeamsheet.js';
import MatchModel from '@/models/match.js';
import { empty } from '@/utils/miscUtils';

export default {
    name: 'commonMatchTeamsheet',
    extends: base,
    components: {
        matchReportsHeader,
        teamsheetSummaryTable
    },
    props: {
        dataMatchId: {
            type: Number,
            mandatory: true,
            default: null,
        },
    },
    data(){
        return {
            apiData: {
                teamsheets: this.loadTeamsheets(),
            },
            matchKickOffDate: null,
            matchTournaments: [],
            matchOfficials: [],
            teamsheets: null,
            teamsheetTeam1Lineup: null,
            teamsheetTeam1Replacement: null,
            teamsheetTeam2Lineup: null,
            teamsheetTeam2Replacement: null,
            teamOneHeadCoach: '',
            teamTwoHeadCoach: '',
            firstMatchInTournament: null,
        };
    },
    computed: {
        backToMatchDetailsLink(){
            return {
                name: this.detailLinkRoute || 'commonMatchDetail',
                params: Object.assign({
                    dataMatchId: this.dataMatchId,
                }, this.detailLinkParams),
            };
        },
        team1Lineup() {
            if(!this.teamsheets || this.teamsheets.teams.length < 2){
                return [];
            }

            const lineup = this.teamsheets.teams[0].team.teamSheets.filter((item) => item.positionName !== 'Replacement');
            return this.filterPlayerData(lineup);
        },
        team2Lineup() {
            if(!this.teamsheets || this.teamsheets.teams.length < 2){
                return [];
            }

            const lineup = this.teamsheets.teams[1].team.teamSheets.filter((item) => item.positionName !== 'Replacement');
            return this.filterPlayerData(lineup);
        },
        team1Subs() {
            if(!this.teamsheets || this.teamsheets.teams.length < 2){
                return [];
            }

            const lineup = this.teamsheets.teams[0].team.teamSheets.filter((item) => item.positionName === 'Replacement');
            return this.filterPlayerData(lineup);
        },
        team2Subs() {
            if(!this.teamsheets || this.teamsheets.teams.length < 2){
                return [];
            }

            const lineup = this.teamsheets.teams[1].team.teamSheets.filter((item) => item.positionName === 'Replacement');
            return this.filterPlayerData(lineup);
        },
    },
    methods: {
        loadTeamsheets(){
            return this.$api.call.match.matchReportsTeamsheetsGetByMatchId(this.dataMatchId).then(result => {
                this.teamsheets = new MatchTeamsheetModel(result);

                const teamOneHeadCoach = this.teamsheets.teams[0].team.teamSheets.filter((item) => item.positionName === 'Head Coach');
                this.teamOneHeadCoach = teamOneHeadCoach[0]?.displayName || '';

                const teamTwoHeadCoach = this.teamsheets.teams[1].team.teamSheets.filter((item) => item.positionName === 'Head Coach');
                this.teamTwoHeadCoach = teamTwoHeadCoach[0]?.displayName || '';

                this.matchKickOffDate = this.teamsheets.match.kickOff;

                if(this.teamsheets.match.tournaments.length){
                    this.$api.call.match.matchSearch({
                        tournamentEqId: this.teamsheets.match.tournaments[0].eqId,
                        paging: {
                            pageIndex: 0,
                            pageSize: 100
                        }
                    }).then(matchData => {
                        const matches = (matchData ? (matchData.data || []) : []).map(data => new MatchModel(data));

                        if(matches.length){
                            const sortedMatches = matches.sort(function(a, b){ return a.kickOff - b.kickOff });
                            this.firstMatchInTournament = sortedMatches[0];
                        }
                    });
                }

                return this.getMatchOfficials();
            });
        },
        reloadTeamsheets(){
            this.apiData.teamsheets = this.loadTeamsheets();
        },
        getMatchOfficials() {
            return this.$api.call.match.matchOfficialsGetByMatchId(this.dataMatchId).then(result => {
                this.matchOfficials = (result || []).map(official => ({
                    position: official.positionName,
                    name: official.displayName,
                    union: official.unionName,
                }));
            });
        },
        filterPlayerData(players) {
            const playerList = [];
            players.forEach(player => {
                const number = player.getPlayerAttribute('shirtNumber');
                if (empty(number)) {
                    return;
                }
                playerList.push(player);
            });

            return playerList;
        },
    },
};
</script>
