<template>
    <div class="pageContent">
        <wrapper v-bind:title="$t('editRankingPenalty')" v-bind:waitFor="apiData.penalty"
            v-bind:waitForRetry="reloadPenalty" v-on:submit="updatePenalty" mainTitle isForm doubleButtons>
            <template v-slot:buttons>
                <actionButton v-bind:to="toPenaltyList" v-bind:loadingWhile="apiData.penaltyUpdate">
                    {{ $t('cancel') }}
                </actionButton>
                <actionButton type="submit" v-bind:loadingWhile="apiData.penaltyUpdate">
                    {{ $t('save') }}
                </actionButton>
            </template>
            <template v-slot:header v-if="error">
                <validations v-bind:errors="error.getErrorMessages($t('requestError'))" v-if="error" />
            </template>
            <template v-slot>
                <editRankingPenaltyForm v-bind:model="penalty" v-bind:sport="sportId"
                    v-bind:errors="error ? error.getErrorIds() : undefined" />
            </template>
        </wrapper>
    </div>
</template>

<script>
import rankingBase from '@/views/administration/rankings/base.vue';
import validations from '@/components/validations.vue';
import editRankingPenaltyForm from '@/components/forms/editRankingPenalty.vue';

import PenaltyModel from '@/models/penalty.js';

export default {
    name: 'administrationRankingPenaltiesEdit',
    extends: rankingBase,
    components: {
        validations,
        editRankingPenaltyForm,
    },
    props: {
        dataPenaltyId: {
            type: Number,
            mandatory: true,
            default: null,
        },
    },
    data() {
        return {
            apiData: {
                penalty: this.loadPenalty(),
                penaltyUpdate: null,
            },
            penalty: null,
            error: null,
        };
    },
    computed: {
        toPenaltyList() {
            return { name: 'administrationRankingPenaltiesIndex' };
        },
    },
    methods: {
        loadPenalty() {
            return this.$api.call.rankings.alterationGetById(this.dataPenaltyId).then(data => {
                this.penalty = PenaltyModel.from(data);
            });
        },
        reloadPenalty() {
            this.apiData.penalty = this.loadPenalty();
        },
        updatePenalty() {
            this.apiData.penaltyUpdate = this.$api.call.rankings.alterationUpdateById(this.dataPenaltyId, this.penalty.toJSON()).then(result => {
                this.error = null;
                this.$router.push(this.toPenaltyList);
            }).catch(error => {
                if (this.$log) {
                    this.$log.warn('penalty update failed', error);
                }
                //show validation errors
                this.error = error;
            });
        },
    },
}
</script>
