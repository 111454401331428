import {empty} from '@/utils/miscUtils';
import dateTime from 'date-and-time';

export const calculateAge = (dateOfBirth, comparisonDate = new Date()) => {
    if (!dateOfBirth) return null;

    const diff = new Date(comparisonDate.getTime() - dateOfBirth.getTime());
    return Math.abs(diff.getUTCFullYear() - 1970);
}

export const isUnderage = (dateOfBirth, threshhold = 19, comparisonDate = new Date()) => {
    if (!dateOfBirth) return null;

    const age = calculateAge(dateOfBirth, comparisonDate);

    return age < threshhold;
}

export const formatDateLong = (date) => {
    let dateToFormat;
    if (empty(date)) {
        return '';
    }

    if (!(date instanceof Date) && !(typeof date === 'string')) {
        return '';
    }

    if (typeof date === 'string') {
        dateToFormat = new Date(date);
    } else {
        dateToFormat = date;
    }
    const returnVal = dateTime.format(dateToFormat, 'DD MMMM YYYY HH:mm');
    return returnVal;
}
