<template>
    <div class="pageContent">
        <wrapper
            v-bind:title="$t('createRankingTeam')"
            v-on:submit="createUnion"
            mainTitle
            isForm
            doubleButtons
        >
            <template v-slot:buttons>
                <actionButton
                    v-bind:to="toCalculationList"
                    v-bind:loadingWhile="apiData.unionCreation"
                >
                    {{ $t('cancel') }}
                </actionButton>
                <actionButton
                    type="submit"
                    v-bind:loadingWhile="apiData.unionCreation"
                >
                    {{ $t('create') }}
                </actionButton>
            </template>
            <template v-slot:header v-if="error">
                <validations v-bind:errors="error.getErrorMessages($t('requestError'))" v-if="error"/>
            </template>
            <template v-slot>
                <createEditCalculationRankingUnion
                    v-bind:model="rankingUnion"
                    v-bind:errors="error ? error.getErrorIds() : undefined"
                    v-bind:sport="sportId"
                />
            </template>
        </wrapper>
    </div>
</template>

<script>
import rankingBase from '@/views/administration/rankings/base.vue';
import validations from '@/components/validations.vue';
import createEditCalculationRankingUnion from '@/components/forms/createEditCalculationRankingUnion.vue';

import RankingUnionModel from '@/models/rankingUnion.js';

export default {
    name: 'administrationCalculationUnionCreate',
    extends: rankingBase,
    components: {
        validations,
        createEditCalculationRankingUnion
    },
    data(){
        return {
            apiData: {
                unionCreation: null,
            },
            rankingUnion: RankingUnionModel.from({}),
            error: null,
        };
    },
    computed: {
        toCalculationList(){
            return { name: 'administrationRankingCalculationsIndex' };
        },
    },
    methods: {
        createUnion() {
            this.rankingUnion.sport = this.sportId;
            if (this.rankingUnion.union) {
                this.rankingUnion.countryIsoCode = this.rankingUnion.union.countryIsoCode || '';
            }

            this.apiData.calculationCreation = this.$api.call.rankings.calculationRankingUnionCreate(this.rankingUnion.toJSON()).then(result => {
                this.error = null;
                this.$router.push(this.toCalculationList);
            }).catch(error => {
                if(this.$log){
                    this.$log.warn('calculation union creation failed', error);
                }

                //show validation errors
                this.error = error;
            });
        },
    },
}
</script>
