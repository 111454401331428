<template>
    <b-table-simple
        class="m-0 personnelTable minWidthNoWrap"
        responsive hover
    >
        <b-thead>
            <b-tr class="headerDisplay">
                <b-th colspan="2" class="text-uppercase">Team Management</b-th>
            </b-tr>
        </b-thead>
        <b-tbody>
            <b-tr v-for="person in teamPersonnel" v-bind:key="person.personName">
                <b-td class="positionDisplay" >
                    {{ person.position }}
                </b-td>
                <b-td>
                    {{ person.personName }}
                </b-td>
            </b-tr>
        </b-tbody>
    </b-table-simple>
</template>

<script>

export default {
    name: 'TeamPersonnelTable7s',
    props: {
        teamPersonnel: {
            type: Array,
            required: true
        }
    },
}
</script>

<style lang="scss" scoped>
    .positionDisplay {
        text-align: end;
    }
    .headerDisplay {
        text-align: center;
    }
</style>
