<template>
    <div class="pageContent">
        <actionButton
            variant="outline-primary"
            class="btn-flat mb-5"
            v-bind:to="backToMatchDetailsLink"
        >
            <icon type="arrow-left" class="align-middle"/> {{ $t('backToPreviousScreen') }}
        </actionButton>

        <matchReportsHeader
            v-bind:waitFor="apiData.stats"
            v-bind:waitForRetry="reloadStats"
            v-bind:title="$t('matchReport')"
            v-bind:data="stats"
        />
        <wrapper
            v-bind:waitFor="apiData.stats"
            v-bind:waitForRetry="reloadStats"
            noPadding noShadow noLine
            v-if="team1Lineup.length || team2Lineup.length"
        >
            <h2 class="text-center mb-5 mt-5">{{ $t('lineup') }}</h2>
            <div class="d-flex flex-row gap-15">
                <matchReportTable v-bind:players="team1Lineup" />
                <matchReportTable v-bind:players="team2Lineup" />
            </div>
        </wrapper>

        <wrapper
            v-bind:waitFor="apiData.stats"
            v-bind:waitForRetry="reloadStats"
            noPadding noShadow noLine
            v-if="team1Subs.length || team2Subs.length"
        >
        <h2 class="text-center mb-5 mt-5">{{ $t('subs') }}</h2>
            <div class="d-flex flex-row gap-15">
                <matchReportTable v-bind:players="team1Subs" />
                <matchReportTable v-bind:players="team2Subs" />
            </div>
        </wrapper>
    </div>
</template>

<script>
import base from '@/views/base.vue';
import icon from '@/components/icon.vue';
import matchReportsHeader from '@/components/matchReportsHeader.vue';
import matchReportTable from '@/components/tables/matchReportTable.vue';
import constants from '@/constants.js';


import MatchStatsModel from '@/models/matchStats.js';

export default {
    name: 'commonMatchReport',
    extends: base,
    components: {
        icon,
        matchReportsHeader,
        matchReportTable
    },
    props: {
        dataMatchId: {
            type: Number,
            mandatory: true,
            default: null,
        },
    },
    data(){
        return {
            apiData: {
                stats: this.loadStats(),
            },
            stats: null,
            matchTournaments: [],
            maxPlayerLength: 0,
            team1Lineup: [],
            team2Lineup: [],
            team1Subs: [],
            team2Subs: [],
        };
    },
    computed: {
        backToMatchDetailsLink(){
            return {
                name: this.detailLinkRoute || 'commonMatchDetail',
                params: Object.assign({
                    dataMatchId: this.dataMatchId,
                }, this.detailLinkParams),
            };
        },
    },
    methods: {
        loadStats(){
            return this.$api.call.match.matchReportsStatsGetByMatchId(this.dataMatchId).then(data => {
                this.stats = new MatchStatsModel(data);
                this.team1Lineup = this.stats.teams[0].players.filter(p => !p.player.isReplacement && p.player.roleName !== constants.personRoleType.management).sort((a, b) => a.player.positionOrder - b.player.positionOrder);
                this.team2Lineup = this.stats.teams[1].players.filter(p => !p.player.isReplacement && p.player.roleName !== constants.personRoleType.management).sort((a, b) => a.player.positionOrder - b.player.positionOrder);
                this.team1Subs = this.stats.teams[0].players.filter(p => p.player.isReplacement).sort((a, b) => a.player.positionOrder - b.player.positionOrder);
                this.team2Subs = this.stats.teams[1].players.filter(p => p.player.isReplacement).sort((a, b) => a.player.positionOrder - b.player.positionOrder);
            });
        },
        reloadStats(){
            this.apiData.stats = this.loadStats();
        },
        getPlayerName(person, isTeamTwo = false){
            let playerName = person?.player?.displayName || '';

            if (!playerName){
                playerName = person?.player?.forenames || '';
                playerName += person?.player?.surname ? ' ' + person.player.surname : '';
            }

            if (isTeamTwo){
                playerName += person?.player?.isCaptain ? ' (c)' : '';
            }
            else
            {
                playerName = (person?.player?.isCaptain ? '(c) ' : '') + playerName;
            }

            return playerName;
        },
    },
};
</script>
