<template>
    <div class="pageContent">
        <detailsHeader
            v-bind:waitFor="apiData.match"
            v-bind:waitForRetry="reloadMatch"
            v-bind:title="$t('matchDetails')"
        >
            <template v-slot:buttons>
                <actionButton
                    variant="danger"
                    v-bind:permission="permissions.declarations.delete"
                    v-b-modal.confirmDeleteDeclaration
                    v-if="hasMatchDeclaration"
                >
                    {{ $t('deleteDeclaration') }}
                </actionButton>
                <actionButton
                    variant="warning"
                    v-bind:permission="permissions.matches.publishStatus"
                    v-b-modal.modal-changeMatchPublishStatus
                >
                    {{ $t('changeStatus') }}
                </actionButton>
                <actionButton
                    variant="warning"
                    v-bind:permission="permissions.matches.publishStatus"
                    v-b-modal.modal-changeMatchPublishTeamsSheetsStatus
                >
                    {{ $t('changeTeamsSheetsStatus') }}
                </actionButton>
                <actionButton
                    variant="secondary"
                    v-bind:href="matchAssistantLink"
                    target="_blank"
                >
                    {{ $t('openMatchAssistant') }}
                </actionButton>
                <actionButton
                    variant="secondary"
                    v-bind:to="toMatchEdit"
                >
                    {{ $t('edit') }}
                </actionButton>
            </template>
            <template v-slot:default>
                <div class="col-12 px-3 px-lg-5 py-4 pt-lg-6" v-if="match">
                    <div class="top col-12">
                        <div class="col-12 px-0 text-center">
                            <ul>
                                <li class="col-12">
                                    <span class="m-0">{{ $fd(match.kickOff, 'dateTime') }}</span>
                                </li>
                                <li class="col-12">
                                    <span>{{ match.venue ? match.venue.name : '' }}</span>
                                    <span>{{ match.venue ? match.venue.locationName : '' }}</span>
                                </li>
                                <li class="col-12">
                                    <span class="m-0">{{ $t('attendance') }}: {{ match.attendance }}</span>
                                </li>
                                <li class="col-12">
                                    <span class="m-0">{{ $t('statusMatch') }}: {{ match.publishStatus }}</span>
                                </li>
                            </ul>
                        </div>
                        <div class="col-12 px-0 clearfix">
                            <div class="float-left col-5">
                                <span class="float-right">
                                    <b-img v-bind:src="match.getImgPathTeamOne()" v-bind:widht="32" v-bind:height="32" class="ml-2">></b-img>
                                </span>
                                <h2 class="float-right text-uppercase m-0 py-2">{{ match.getTeamOneName() }}</h2>
                            </div>
                            <div class="float-left col-2 text-center">{{ match.teamOneScore }} : {{ match.teamTwoScore }}</div>
                            <div class="float-left col-5">
                                <span class="float-left">
                                    <b-img v-bind:src="match.getImgPathTeamTwo()" v-bind:widht="32" v-bind:height="32" class="mr-2"></b-img>
                                </span>
                                <h2 class="float-left text-uppercase m-0 py-2">{{ match.getTeamTwoName() }}</h2>
                            </div>
                        </div>
                        <div class="col-12 px-0 clearfix">
                            <div class="float-right col-5">
                                <span class="float-left text-uppercase">
                                    {{ $t('statusTeamSheet') }}:
                                    {{ match.teamTwo ? match.teamTwo.teamSheetPublishStatus : '' }}
                                </span>
                            </div>
                            <div class="float-left col-2 text-center">&nbsp;</div>
                            <div class="float-left col-5">
                                <span class="float-right text-uppercase">
                                    {{ $t('statusTeamSheet') }}:
                                    {{ match.teamOne ? match.teamOne.teamSheetPublishStatus : '' }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template v-slot:more>
                <ul class="my-4 mt-lg-6">
                    <li
                        v-for="(data, i) in moreHeaderData"
                        v-bind:key="i"
                        class="row py-0"
                    >
                        <span class="col-6 text-right mr-0">{{ data.label }}</span>
                        <span class="col-6">{{ data.content }}</span>
                    </li>
                </ul>
            </template>
        </detailsHeader>

        <subNavigation v-bind:data-config="subnavigation" />

        <list
            v-bind:items="matchTournaments"
            v-bind:title="$tc('tournament', 2)"
            v-bind:waitFor="apiData.matchTournaments"
            v-bind:waitForRetry="reloadMatchTournaments"
            v-bind:hide="hideElement(1)"
        >
            <template v-slot:buttons>
                <actionButton
                    variant="outline-primary"
                    class="btn-flat"
                    v-bind:permission="permissions.matches.tournaments.create"
                    v-b-modal.modal-assignToTournament
                >
                    {{ $t('assignToTournament') }}
                </actionButton>
            </template>
            <template v-slot:header>
                <matchTournamentRow
                    showDateRange showDetail showRemove
                    isHeadline
                />
            </template>
            <template v-slot="{ item: matchTournament, role }">
                <matchTournamentRow
                    showDateRange showDetail showRemove showEditTournamentAssignment
                    v-bind:matchTournament="matchTournament"
                    v-bind:role="role"
                    v-bind:removePermission="permissions.matches.tournaments.delete"
                    v-on:editTournamentAssignment="editTournamentAssignment"
                    v-bind:removeHandler="removeMatchTournament"
                />
            </template>
        </list>

        <list
            v-bind:items="matchOfficials"
            v-bind:title="$tc('official', 2)"
            v-bind:waitFor="apiData.matchOfficials"
            v-bind:waitForRetry="reloadMatchOfficials"
            v-bind:hide="hideElement(2)"
        >
            <template v-slot:buttons>
                <actionButton
                    variant="outline-primary"
                    class="btn-flat"
                    v-bind:permission="permissions.matches.officials.create"
                    v-b-modal.modal-addOfficial
                >
                    {{ $t('addOfficial') }}
                </actionButton>
            </template>
            <template v-slot:header>
                <officialRow
                    showUnionFromRootUnionObject showPosition showDetail showRemove
                    isHeadline
                />
            </template>
            <template v-slot="{ item: official, role }">
                <officialRow
                    showUnionFromRootUnionObject showPosition showDetail showRemove
                    v-bind:person="official"
                    v-bind:role="role"
                    v-bind:removePermission="permissions.matches.officials.delete"
                    v-bind:removeHandler="removeMatchOfficial"
                />
            </template>
        </list>

        <list
            v-bind:items="teamOnePlayers"
            v-bind:title="match ? match.getTeamOneName() : $tc('team', 1) + ' 1'"
            v-bind:waitFor="apiData.teamOnePlayers"
            v-bind:waitForRetry="reloadTeamOnePlayers"
            v-bind:hide="hideElement(3)"
        >
            <template v-slot:header>
                <playerRow
                    showNr
                    showDetail
                    showPosition
                    showActionsUsedInMatchCheckbox
                    showActionsPlayerOfTheMatchCheckbox
                    showActionsEditPlayer
                    showRepresenting
                    isHeadline
                    v-bind:headlineConfig="{ name: $t('displayName') }"
                />
            </template>
            <template v-slot="{ item: person, role }">
                <playerRow
                    showNr
                    showDetail
                    showPosition
                    showActionsUsedInMatchCheckbox
                    showActionsPlayerOfTheMatchCheckbox
                    showActionsEditPlayer
                    showRepresenting
                    v-bind:person="person"
                    v-bind:role="role"
                    readonly
                />
            </template>
        </list>

        <list
            v-bind:items="teamTwoPlayers"
            v-bind:title="match ? match.getTeamTwoName() : $tc('team', 1) + ' 2'"
            v-bind:waitFor="apiData.teamTwoPlayers"
            v-bind:waitForRetry="reloadTeamTwoPlayers"
            v-bind:hide="hideElement(4)"
        >
            <template v-slot:header>
                <playerRow
                    showNr
                    showDetail
                    showPosition
                    showActionsUsedInMatchCheckbox
                    showActionsPlayerOfTheMatchCheckbox
                    showActionsEditPlayer
                    showRepresenting
                    isHeadline
                    v-bind:headlineConfig="{ name: $t('displayName'), team: $t('representing') }"
                />
            </template>
            <template v-slot="{ item: person, role }">
                <playerRow
                    showNr
                    showDetail
                    showPosition
                    showActionsUsedInMatchCheckbox
                    showActionsPlayerOfTheMatchCheckbox
                    showActionsEditPlayer
                    showRepresenting
                    v-bind:person="person"
                    v-bind:role="role"
                    readonly
                />
            </template>
        </list>

        <wrapper
            v-bind:title="$t('matchSummary')"
            v-bind:waitFor="apiData.matchSummary"
            v-bind:waitForRetry="reloadMatchSummary"
            v-bind:hide="hideElement(5)"
        >
            <template v-slot:buttons>
                <actionButton
                    variant="outline-primary"
                    class="btn-flat"
                    v-on:click="printSegment(5)"
                >
                    {{ $t('print') }}
                </actionButton>
            </template>
            <template v-slot>
                <matchSummaryRow v-bind:matchSummary="matchSummary"/>
            </template>
        </wrapper>

        <disciplinaryList
            v-bind:title="$tc('disciplinary', 2)"
            v-bind:rowProps="rowProps"
            v-bind:waitFor="apiData.matchDisciplinary"
            v-bind:content="matchDisciplinary"
            v-bind:rowEditPermission="permissions.matches.disciplinaries.update"
            v-bind:rowRemovePermission="permissions.matches.disciplinaries.delete"
            v-on:updated="updateDisciplinaryComplete"
            v-bind:hide="hideElement(6)"
        >
            <template v-slot:buttons>
                <actionButton
                    variant="outline-primary"
                    v-bind:permission="permissions.matches.disciplinaries.create"
                    v-b-modal.modal-addDisciplinary
                >
                    {{ $t('addDisciplinaryAction') }}
                </actionButton>
            </template>
        </disciplinaryList>

        <list
            v-bind:items="teamsKits"
            v-bind:title="$t('matchKits')"
            isRow
            v-bind:waitFor="apiData.teamTwoPlayers"
            v-bind:waitForRetry="reloadTeamTwoPlayers"
            v-if="teamsKits"
            v-bind:hide="hideElement(7)"
        >
            <template v-slot:buttons>
                <!-- v-bind:permission="permissions.matches.officials.create" -->
                <actionButton
                    variant="outline-primary"
                    v-b-modal.modal-selectKits
                >
                    {{ $t('selectKits') }}
                </actionButton>
            </template>
            <template v-slot="{ item: teamKit, role }">
                <teamKitRow
                    v-bind:teamKit="teamKit"
                    v-bind:role="role"
                    v-bind:readonly="true"
                />
            </template>
        </list>

        <wrapper
            v-bind:title="$tc('report', 2)"
            v-bind:hide="hideElement(8)"
        >
            <simpleRow
                v-bind:text="$t('matchReport')"
                v-bind:moveTo="toMatchReport"
                openInNewTab
                showMove textLeft normalText
            />
            <simpleRow
                v-bind:text="$t('matchStats')"
                v-bind:moveTo="toMatchStats"
                openInNewTab
                showMove textLeft normalText
            />
            <simpleRow
                v-bind:text="$t('matchTimeline')"
                v-bind:moveTo="toMatchTimeline"
                openInNewTab
                showMove textLeft normalText
            />
            <simpleRow
                v-bind:text="$t('highlights')"
                v-bind:moveTo="toMatchHighlights"
                openInNewTab
                showMove textLeft normalText
            />
            <simpleRow
                v-bind:text="$t('teamsheets')"
                v-bind:moveTo="toMatchTeamsheets"
                openInNewTab
                showMove textLeft normalText
            />
            <simpleRow
                v-bind:text="$t('teamsheetSummary')"
                v-bind:moveTo="toMatchTeamsheetSummary"
                openInNewTab
                showMove textLeft normalText
            />
            <simpleRow
                v-bind:text="$t('teamsheets7s')"
                v-bind:moveTo="toMatchTeamsheets7s"
                v-if="['MRS', 'WRS'].includes(match.sport)"
                openInNewTab
                showMove textLeft normalText
            />
        </wrapper>

        <wrapper
            v-bind:waitFor="apiData.matchMappings"
            v-bind:waitForRetry="reloadMatchMappings"
            v-bind:title="$t('mapping')"
            v-bind:hide="hideElement(9)"
        >
            <b-row cols="1" cols-lg="6" align-h="center" v-if="hasMatchMappings">
                <b-card header-tag="header" class="mt-2 mb-5">
                    <template #header>
                        <h6 class="mb-0">{{ $t('matchMapping') }}</h6>
                    </template>
                    <b-card-text>
                        <b-table class="mb-0" head-variant="light" :items="matchMappings.mapping" :fields="mappingProviderFields" v-if="hasMatchMappings"/>
                    </b-card-text>
                </b-card>
            </b-row>
            <b-row
                cols="1"
                cols-lg="2"
            >
                <b-col>
                    <h1><span>{{ matchMappingTeamOne.name }}</span></h1>
                    <h3 class="text-center mb-2 mt-2" v-if="hasMatchMappingData && matchMappingTeamOne.mapping.length">{{ $t('teamMapping') }}</h3>
                    <b-table head-variant="light" :items="matchMappingTeamOne.mapping" :fields="mappingProviderFields" v-if="hasMatchMappingData"/>
                    <personMappingList
                        v-bind:title="$tc('player', 2)"
                        v-bind:waitFor="apiData.matchMappings"
                        v-bind:content="matchMappingTeamOne.players"/>
                    <personMappingList
                        v-bind:title="$t('staff')"
                        v-bind:waitFor="apiData.matchMappings"
                        v-bind:content="matchMappingTeamOne.staff"/>
                </b-col>
                <b-col>
                    <h1><span>{{ matchMappingTeamTwo.name }}</span></h1>
                    <h3 class="text-center mb-2 mt-2" v-if="hasMatchMappingData && matchMappingTeamTwo.mapping.length">{{ $t('teamMapping') }}</h3>
                    <b-table head-variant="light" :items="matchMappingTeamTwo.mapping" :fields="mappingProviderFields" v-if="hasMatchMappingData"/>
                    <personMappingList
                        v-bind:title="$tc('player', 2)"
                        v-bind:waitFor="apiData.matchMappings"
                        v-bind:content="matchMappingTeamTwo.players"/>
                    <personMappingList
                        v-bind:title="$t('staff')"
                        v-bind:waitFor="apiData.matchMappings"
                        v-bind:content="matchMappingTeamTwo.staff"/>
                </b-col>
            </b-row>
        </wrapper>

        <changeMatchPublishStatusModal
            id="modal-changeMatchPublishStatus"
            v-bind:title="$t('changeMatchStatus')"
            v-bind:publishStatus="match ? match.publishStatus : null"
            v-bind:confirmHandler="updateMatchPublishStatus"
            v-on:confirmed="updateMatchPublishStatusComplete"
        />

        <changeMatchTeamsSheetsPublishStatusModal
            id="modal-changeMatchPublishTeamsSheetsStatus"
            v-bind:title="$t('changeTeamsSheetsStatus')"
            v-bind:team1SheetPublishStatus="(match && match.teamOne) ? match.teamOne.teamSheetPublishStatus : ''"
            v-bind:team2SheetPublishStatus="(match && match.teamTwo) ? match.teamTwo.teamSheetPublishStatus : ''"
            v-bind:team1Name="(match && match.teamOne) ? match.teamOne.team.teamName : ''"
            v-bind:team2Name="(match && match.teamTwo) ? match.teamTwo.team.teamName : ''"
            v-bind:confirmHandler="updateTeamsSheetsPublishStatus"
            v-on:confirmed="updateTeamsSheetsPublishStatusComplete"
        />

        <addOfficialModal
            id="modal-addOfficial"
            v-bind:title="$t('addOfficial')"
            v-bind:confirmHandler="addOfficial"
            v-on:confirmed="addOfficialComplete"
        />
        <addEditDisciplinaryModal
            id="modal-addDisciplinary"
            v-bind:title="$t('addDisciplinary')"
            v-bind:matchId="dataMatchId"
            v-bind:disciplinary="newDisciplinary"
            v-bind:matchReadonly="true"
            v-bind:teamReadonly="false"
            v-bind:confirmHandler="addDisciplinary"
            v-on:confirmed="addDisciplinaryComplete"
        />
        <assignMatchToTournamentModal
            id="modal-assignToTournament"
            v-bind:title="$t('assignToTournament')"
            v-bind:matchTournamentToEdit="newMatchTournament"
            v-bind:confirmHandler="addMatchToTournament"
            v-on:confirmed="addMatchToTournamentComplete"
        />

        <selectKitsModal
            id="modal-selectKits"
            v-bind:title="$t('selectMatchKits')"
            v-bind:matchKits="teamsKits"
            v-bind:dataMatchId="dataMatchId"
            v-bind:hideSelectTeamKit="false"
            v-bind:confirmHandler="kitsUpdate"
            v-on:confirmed="kitsUpdateComplete"
        />

        <confirmModal
            v-bind:id="'confirmDeleteDeclaration'"
            v-bind:text="$t('pleaseConfirmDeletion', { name: $t('matchDeclaration') })"
            v-bind:data="matchDeclarationId"
            v-bind:confirmHandler="deleteMatchDeclarationHandler"
            v-on:confirmed="deleteMatchDeclarationComplete"
        />
    </div>
</template>

<script>
import base from '@/views/base.vue';
import subNavigation from '@/components/subnavigation.vue';
import detailsHeader from '@/components/detailsHeader.vue';

import matchTournamentRow from '@/components/rows/matchTournament.vue';
import officialRow from '@/components/rows/official.vue';
import playerRow from '@/components/rows/player.vue';
import matchSummaryRow from '@/components/rows/matchSummary.vue';
import teamKitRow from '@/components/rows/teamKit.vue';
import simpleRow from '@/components/rows/simple.vue';
import constants from '@/constants.js';

import disciplinaryList from '@/components/lists/disciplinary.vue';
import personMappingList from '@/components/lists/personMapping.vue';

import changeMatchPublishStatusModal from '@/components/modals/changePublishStatus.vue';
import changeMatchTeamsSheetsPublishStatusModal from '@/components/modals/changeTeamSheetPublishStatus.vue';
import addOfficialModal from '@/components/modals/addOfficial.vue';
import addEditDisciplinaryModal from '@/components/modals/addEditDisciplinary.vue';
import assignMatchToTournamentModal from '@/components/modals/assignMatchToTournament.vue';
import selectKitsModal from '@/components/modals/selectKits.vue';
import confirmModal from '@/components/modals/confirm.vue';

import MatchModel from '@/models/match.js';
import PlayerModel from '@/models/person/player.js';
import OfficialModel from '@/models/person/official.js';
import SeriesModel from '@/models/series.js';
import MatchTournamentModel from '@/models/matchTournament.js';
import MatchSummaryModel from '@/models/matchSummary.js';
import DisciplinaryModel from '@/models/disciplinary.js';
import TeamKitModel from '@/models/person/teamkit.js';
import MatchMappingModel from '@/models/mapping/match.js';
import match from '../../../router/match';

export default {
    name: 'commonMatchDetail',
    extends: base,
    components: {
        subNavigation,
        detailsHeader,
        matchTournamentRow,
        officialRow,
        playerRow,
        matchSummaryRow,
        teamKitRow,
        simpleRow,
        disciplinaryList,
        changeMatchPublishStatusModal,
        changeMatchTeamsSheetsPublishStatusModal,
        addOfficialModal,
        addEditDisciplinaryModal,
        assignMatchToTournamentModal,
        selectKitsModal,
        confirmModal,
        personMappingList,
    },
    props: {
        dataMatchId: {
            type: Number,
            mandatory: true,
            default: null,
        },
    },
    data(){
        this.apiData = {
            match: this.loadMatch(),
        };

        return {
            apiData: {
                match: this.apiData.match,
                matchTournaments: this.loadMatchTournaments(),
                matchOfficials: this.loadMatchOfficials(),
                teamOnePlayers: this.loadTeamOnePlayers(),
                teamTwoPlayers: this.loadTeamTwoPlayers(),
                matchSummary: this.loadMatchSummary(),
                matchDisciplinary: this.loadMatchDisciplinary(),
                matchDeclaration: this.loadMatchDeclaration(),
                matchMappings: this.loadMatchMappings(),
            },
            matchPublishStatus: null,
            match: null,
            matchDeclarationId: null,
            matchTournaments: [],
            matchOfficials: [],
            personEqId: '',
            teamOnePlayers: [],
            teamTwoPlayers: [],
            matches: [],
            matchSummary: '',
            matchDisciplinary: [],
            newDisciplinary: new DisciplinaryModel({ matchId: this.dataMatchId }),
            teamsKits: [],
            newMatchTournament: new MatchTournamentModel({ matchId: this.dataMatchId }),
            matchMappings: null,
            isAllowedMatchMapping: false,

            subnavigation: {
                active: 0,
                elements: {
                    0: {
                        label: this.$t('all'),
                    },
                    1: {
                        label: this.$tc('tournament', 2),
                    },
                    2: {
                        label: this.$tc('official', 2),
                    },
                    3: {
                        label: '',
                    },
                    4: {
                        label: '',
                    },
                    5: {
                        label: this.$t('matchSummary'),
                    },
                    6: {
                        label: this.$t('disciplinary'),
                    },
                    7: {
                        label: this.$tc('teamkit', 2),
                    },
                    8: {
                        label: this.$tc('report', 2),
                    },
                    9: {
                        label: this.$t('mapping'),
                        hidden: !this.isAllowedMatchMapping,
                    },
                }
            },
            series: new SeriesModel({
                id: 1,
                seriesTypeId: 1,
                name: 'HSBC World Rugby Seven Series 1',
                sport: 'MRU',
                mainSeason: 2019,
            }),
            rowProps: {
                showOrder: true,
                matchReadonly: true,
                teamReadonly: true,
                citingCommissionerReadonly: true,
            },
            mappingProviderFields: ['provider', 'id'],
        };
    },
    methods: {
        updateMatchPublishStatus(data) {
            return this.$api.call.match.publishStatusMatchUpdate(this.dataMatchId, { publishStatus: data });
        },
        updateMatchPublishStatusComplete(){
            this.reloadMatch();
        },
        updateTeamsSheetsPublishStatus(data) {
            return Promise.all([
                this.$api.call.match.publishStatusTeamSheetUpdate(this.match.teamOne.id, { publishStatus: data.team1SheetPublishStatus }),
                this.$api.call.match.publishStatusTeamSheetUpdate(this.match.teamTwo.id, { publishStatus: data.team2SheetPublishStatus }),
            ]);
        },
        updateTeamsSheetsPublishStatusComplete(){
            this.reloadMatch();
        },

        loadMatch(){
            return this.$api.call.match.matchGetById(this.dataMatchId).then(data => {
                this.match = new MatchModel(data);
                this.subnavigation.elements[3].label = this.match.teamOne ? this.match.getTeamOneName() : this.$tc('team', 1) + ' 1';
                this.subnavigation.elements[4].label = this.match.teamTwo ? this.match.getTeamTwoName() : this.$tc('team', 1) + ' 2';

                //reload stuff that require the tournament eqid so they can finally load
                this.reloadMatchTournaments();
                this.reloadMatchOfficials();
                this.reloadTeamOnePlayers();
                this.reloadTeamTwoPlayers();
                this.reloadMatchSummary();
                this.reloadMatchDisciplinary();
                this.reloadMatchDeclaration();
                this.reloadMatchMappings();
            });
        },
        reloadMatch(){
            this.apiData.match = this.loadMatch();
        },

        loadMatchDeclaration(){
            if(!this.match){
                return new Promise((resolve, reject) => this.apiData.match.catch(reject));
            }

            return this.$api.call.termsOfParticipation.matchDeclarationByMatchEqId(this.match.eqId).then(matchDeclarationData => {
                this.matchDeclarationId = matchDeclarationData?.id;
            }).catch(error => {
                if (error.status === 404) {
                    console.warn('No match declaration found for match', this.match.eqId);
                    return;
                }
                throw error;
            });
        },
        reloadMatchDeclaration(){
            this.apiData.matchDeclaration = this.loadMatchDeclaration();
        },

        loadMatchTournaments(){
            if(!this.match){
                return new Promise((resolve, reject) => this.apiData.match.catch(reject));
            }

            return this.$api.call.match.matchGetTournaments(this.match.id).then(result => {
                this.matchTournaments = (result || []).map(data => new MatchTournamentModel(data));
            });
        },
        reloadMatchTournaments(){
            this.apiData.matchTournaments = this.loadMatchTournaments();
        },

        loadMatchMappings(){
            if(!this.match){
                return new Promise((resolve, reject) => this.apiData.match.catch(reject));
            }

            const user = this.$auth.getUser();
            this.isAllowedMatchMapping = user.areAllowed([this.permissions.mapping.match.retrieve]);
            this.$set(this.subnavigation.elements[9], 'hidden', !this.isAllowedMatchMapping);

            return this.$api.call.mapping.matchMappingGetByEqId(this.match.eqId).then(matchMappingData => {
                this.matchMappings = new MatchMappingModel(matchMappingData);
            });
        },
        reloadMatchMappings(){
            this.apiData.matchMappings = this.loadMatchMappings();
        },

        loadMatchOfficials(){
            //request requires the tournament eqid, so until the tournament is loaded we use a promise that never resolves
            if(!this.match){
                return new Promise((resolve, reject) => this.apiData.match.catch(reject));
            }

            return this.$api.call.match.matchOfficialsGetByMatchId(this.match.id).then(result => {
                this.matchOfficials = (result || []).map(data => new OfficialModel(data));
            });
        },
        reloadMatchOfficials(){
            this.apiData.matchOfficials = this.loadMatchOfficials();
        },

        loadTeamOnePlayers() {
            //request requires match data, so until the match is loaded we use a promise that never resolves
            if(!this.match){
                return new Promise((resolve, reject) => this.apiData.match.catch(reject));
            }

            if (this.match.teamOne) {
                this.$api.call.match.matchTeamKitByMatchTeamEqId(this.match.teamOne.eqId)
                    .catch(error => {
                        if (error.status !== 404) {
                            throw error;
                        }
                        return {};
                    })
                    .then(result => {
                        const data = result && result.length ? result[0] : {} || {};

                        data.matchTeamId = this.match.teamOne.id;
                        data.teamName = this.match.teamOne.name;

                        this.teamsKits.unshift(new TeamKitModel(data));
                    });

                return this.$api.call.match.matchTeamPlayerGetByMatchTeamId(this.match.teamOne.id).then(playersData => {
                    this.teamOnePlayers = (playersData.matchTeamPersons || []).map(data => new PlayerModel(data));
                });
            }
        },
        reloadTeamOnePlayers() {
            this.apiData.teamOnePlayers = this.loadTeamOnePlayers();
        },

        loadTeamTwoPlayers(){
            //request requires match data, so until the match is loaded we use a promise that never resolves
            if(!this.match){
                return new Promise((resolve, reject) => this.apiData.match.catch(reject));
            }

            if (this.match.teamTwo) {
                this.$api.call.match.matchTeamKitByMatchTeamEqId(this.match.teamTwo.eqId)
                    .catch(error => {
                        if (error.status !== 404) {
                            throw error;
                        }
                        return {};
                    })
                    .then(result => {
                        const data = result && result.length ? result[0] : {} || {};

                        data.matchTeamId = this.match.teamTwo.id;
                        data.teamName = this.match.teamTwo.name;

                        this.teamsKits.push(new TeamKitModel(data));
                    });

                return this.$api.call.match.matchTeamPlayerGetByMatchTeamId(this.match.teamTwo.id).then(playersData => {
                    this.teamTwoPlayers = (playersData.matchTeamPersons || []).map(data => new PlayerModel(data));
                });
            }
        },
        reloadTeamTwoPlayers(){
            this.apiData.teamTwoPlayers = this.loadTeamTwoPlayers();
        },

        loadMatchSummary(){
            return this.$api.call.match.matchSummaryGetByMatchId(this.dataMatchId).then(result => {
                this.matchSummary = MatchSummaryModel.from(result);
            });
        },
        reloadMatchSummary(){
            this.apiData.matchSummary = this.loadMatchSummary();
        },

        loadMatchDisciplinary(){
            //request requires the tournament eqid, so until the tournament is loaded we use a promise that never resolves
            if(!this.match){
                return new Promise((resolve, reject) => this.apiData.match.catch(reject));
            }

            return this.$api.call.match.matchTimelineDisciplinaryByMatchEqId(this.match.eqId).then(result => {
                this.matchDisciplinary = (result || []).map(data => new DisciplinaryModel(data));
            });
        },
        reloadMatchDisciplinary(){
            this.apiData.matchDisciplinary = this.loadMatchDisciplinary();
        },
        addDisciplinary(disciplinary) {
            return this.$api.call.match.matchTimelineDisciplinaryCreateWithTimeline(disciplinary.toTimelineDisciplinaryJSON())
                .then(data => new DisciplinaryModel(data));
        },
        addDisciplinaryComplete(){
            this.matchDisciplinary = [];
            this.reloadMatchDisciplinary();
        },
        updateDisciplinaryComplete(){
            this.matchDisciplinary = [];
            this.reloadMatchDisciplinary();
        },

        hideElement(index){
            return this.subnavigation.active !== 0 && this.subnavigation.active !== index;
        },

        addOfficial(official) {
            return this.$api.call.match.matchOfficialCreateByPerson({
                matchId: this.dataMatchId,
                personEqId: official.personEqId,
                positionEqId: official.positionEqId,
            });
        },
        addOfficialComplete(){
            this.reloadMatchOfficials();
        },
        removeMatchOfficial(person) {
            return this.$api.call.match.matchOfficialDeleteById(person.matchOfficialId).then(result => {
                this.matchOfficials = [];
                this.reloadMatchOfficials();
            });
        },
        addMatchToTournament(data) {
            return this.$api.call.match.createMatchTournament(data.toJSON()).then(() => {
                this.matchTournaments = [];
                this.reloadMatchTournaments();
            });
        },
        addMatchToTournamentComplete(){
            this.matchTournaments = [];
            this.newMatchTournament = new MatchTournamentModel({ matchId: this.dataMatchId });
            this.reloadMatchTournaments();
        },
        removeMatchTournament(matchTournament){
            return this.$api.call.match.deleteMatchTournamentByMatchTournamentId(matchTournament.id).then(() => {
                this.matchTournaments = [];
                this.reloadMatchTournaments();
            });
        },

        kitsUpdate(kits){
            return Promise.all(kits.map(kit => {
                if (!kit.isUndefined()) {
                    return this.$api.call.match.addKitToMatchTeam(kit.matchTeamId, kit);
                }
            }));
        },
        kitsUpdateComplete(){
            this.teamsKits = [];
            this.reloadTeamOnePlayers();
            this.reloadTeamTwoPlayers();
        },
        editTournamentAssignment(data){
            this.$api.call.match.matchTournamentUpdateById(data.matchTournament.id, data.matchTournament.toJSON()).then(() => {
                this.matchTournaments = [];
                this.reloadMatchTournaments();
            });
        },
        deleteMatchDeclarationHandler(data){
            return this.$api.call.termsOfParticipation.declarationDeleteById(constants.declarationTypes.match, data);
        },
        deleteMatchDeclarationComplete(){
            this.matchDeclarationId = null;

            this.reloadMatch();
        },
    },
    computed: {
        toMatchEdit(){
            return { name: 'commonMatchEdit', params: { dataMatchId: this.dataMatchId } };
        },
        matchAssistantLink(){
            return this.$config.matchManagerUrl + '/Match/Details?matchId=' + this.dataMatchId;
        },
        getCoverageLevelName() {
            let name = '';
            switch(this.match.level) {
                case 1:
                    name = this.$t('score');
                    break;
                case 2:
                    name = this.$t('teamSheetsAndActions');
                    break;
                case 3:
                    name = this.$t('everything');
                    break;
                default:
                    name = this.$t('noData');
            }
            return name;
        },
        toMatchReport(){
            return { name: 'commonMatchReport', params: { dataMatchId: this.dataMatchId } };
        },
        toMatchStats(){
            return { name: 'commonMatchStats', params: { dataMatchId: this.dataMatchId } };
        },
        toMatchTimeline(){
            return { name: 'commonMatchTimeline', params: { dataMatchId: this.dataMatchId } };
        },
        toMatchHighlights(){
            return { name: 'commonMatchHighlights', params: { dataMatchId: this.dataMatchId } };
        },
        toMatchTeamsheets(){
            return { name: 'commonMatchTeamsheets', params: { dataMatchId: this.dataMatchId } };
        },
        toMatchTeamsheets7s(){
            return { name: 'commonMatchTeamsheets7s', params: { dataMatchId: this.dataMatchId } };
        },
        toMatchTeamsheetSummary(){
            return { name: 'commonMatchTeamsheetSummary', params: { dataMatchId: this.dataMatchId } };
        },
        moreHeaderData(){
            if(!this.match){
                return [];
            }

            return [
                {
                    label: this.$t('matchClock'),
                    content: this.match.matchClock,
                },
                {
                    label: this.$t('status'),
                    content: this.match.matchStatus ? this.match.matchStatus.name : '',
                },
                {
                    label: this.$t('coverageLevel'),
                    content: this.getCoverageLevelName,
                },
                {
                    label: this.$t('pitchConditions'),
                    content: this.match.matchCondition ? this.match.matchCondition.pitchConditions : '',
                },
                {
                    label: this.$t('weather'),
                    content: this.match.matchCondition ? this.match.matchCondition.weather : '',
                },
                {
                    label: this.$t('minTemperature'),
                    content: this.match.matchCondition.minimumTemperature ? `${this.match.matchCondition.minimumTemperature}` : '',
                },
                {
                    label: this.$t('maxTemperature'),
                    content: this.match.matchCondition.maximumTemperature ? `${this.match.matchCondition.maximumTemperature}` : '',
                },
                {
                    label: this.$t('windConditions'),
                    content: this.match.matchCondition.windConditions ? `${this.match.matchCondition.windConditions}` : '',
                },
                {
                    label: this.$t('commissionersReportReceived'),
                    content: this.match.isCommissionersReportReceived ? this.$t('yes') : this.$t('no'),
                },
                {
                    label: this.$t('equivalentReportReceived'),
                    content: this.match.equivalentReport ? this.$t('yes') : this.$t('no'),
                },
                {
                    label: this.$t('displayNotes'),
                    content: this.match.displayNotes,
                },
            ];
        },
        hasMatchDeclaration() {
            return this.matchDeclarationId;
        },
        matchMappingTeamOne() {
            // return empty mapping if no data to avoid errors in the template
            return this.matchMappings?.teams[0] || { mapping: [] };
        },
        matchMappingTeamTwo() {
            // return empty mapping if no data to avoid errors in the template
            return this.matchMappings?.teams[1] || { mapping: [] };
        },
        hasMatchMappings() {
            return this.matchMappings?.mapping?.length;
        },
        hasMatchMappingData() {
            return this.matchMappings;
        },
    },
};
</script>
