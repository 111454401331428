<template>
    <div class="pageContent">
        <detailsHeader
            v-bind:title="$t('tournamentDetails')"
            v-bind:name="tournament ? tournament.name : ''"
            v-bind:nameSub="tournament ? $t('dateRangeFormat', { start: $fd(tournament.dateFrom, 'date'), end: $fd(tournament.dateUntil, 'date') }) : ''"
            v-bind:imagePath="tournament ? tournament.getImgPath() : null"
            v-bind:details="detailData"
            v-bind:moreDetails="moreDetailData"
            v-bind:waitFor="apiData.tournament"
            v-bind:waitForRetry="reloadTournament"
        >
            <template v-slot:buttons>
                <actionButton
                    variant="danger"
                    v-bind:permission="permissions.declarations.delete"
                    v-b-modal.confirmDeleteDeclaration
                    v-if="hasTournamentDeclaration"
                >
                    {{ $t('deleteDeclaration') }}
                </actionButton>
                <actionButton
                    variant="warning"
                    v-bind:permission="permissions.tournaments.publishStatus"
                    v-b-modal.modal-changePublishTournamentStatus
                >
                    {{ $t('changeStatus') }}
                </actionButton>
                <actionButton
                    variant="secondary"
                    v-bind:to="editTournamentLink(dataTournamentId)"
                >
                    {{ $t('edit') }}
                </actionButton>
            </template>
        </detailsHeader>

        <subNavigation v-if="subnavigation" v-bind:data-config="subnavigation" />

        <list
            v-bind:items="tournamentMatches"
            v-bind:title="$tc('match', 2)"
            v-bind:waitFor="apiData.tournamentMatches"
            v-bind:waitForRetry="reloadTournamentMatches"
            v-bind:hide="hideElement(1)"
        >
            <template v-slot:buttons>
                <actionButton
                    variant="secondary"
                    v-bind:permission="permissions.tournaments.matches.representing"
                    v-bind:loadingWhile="apiData.addRepresentingToMatchesLog"
                    loadingThrobber
                    v-on:click="addRepresentingToMatches"
                >
                    {{ $t('AddRepresentingToMatches') }}
                </actionButton>
                <actionButton
                    class="d-lg-block"
                    variant="outline-primary"
                    v-bind:to="createTournamentMatchLink()"
                >
                    {{ $t('create') }}
                </actionButton>
            </template>
            <template v-slot:header>
                <matchRow
                    showScore showNumber showStage showGroup showKickOffTime showDetail
                    isHeadline showPublishStatus
                />
            </template>
            <template v-slot="{ item: match, role }">
                <matchRow
                    showScore showNumber showStage showGroup showKickOffTime showDetail showPublishStatus
                    v-bind:match="match"
                    v-bind:role="role"
                />
            </template>
        </list>

        <list
            v-bind:items="tournamentTeams"
            v-bind:title="$tc('team', 2)"
            v-bind:waitFor="apiData.tournamentTeams"
            v-bind:waitForRetry="reloadTournamentTeams"
            v-bind:hide="hideElement(2)"
        >
            <template v-slot:buttons>
                <actionButton
                    class="d-lg-block"
                    variant="warning"
                    v-bind:permission="permissions.tournaments.changeTeamStatuses"
                    v-b-modal.modal-changeTeamStatus
                >
                    {{ $t('changeTeamStatuses') }}
                </actionButton>
                <actionButton
                    class="d-lg-block"
                    variant="outline-primary"
                    v-bind:to="createTournamentTeamLink()"
                >
                    {{ $t('addTeam') }}
                </actionButton>
            </template>
            <template v-slot:header>
                <teamRow
                    showShortName showTournamentTeamStatus showDetail showRemove detailLinkTournamentTeam
                    isHeadline
                    v-bind:showPoints="showEventSeriesPoints"
                    v-bind:headlineConfig="{ points: $t('eventSeriesPoints') }"
                />
            </template>
            <template v-slot="{ item: team, role }">
                <teamRow
                    showShortName showTournamentTeamStatus showDetail showRemove detailLinkTournamentTeam
                    v-bind:team="team"
                    v-bind:role="role"
                    v-bind:removePermission="permissions.tournaments.teams.delete"
                    detailLinkRoute="commonTournamentTeamDetail"
                    v-bind:showPoints="showEventSeriesPoints"
                    v-bind:dataTournamentId="dataTournamentId"
                    v-bind:detailLinkParams="{ dataTournamentId: dataTournamentId, dataTournamentTeamId: team.tournamentTeamId }"
                    v-bind:removeHandler="removeTournamentTeam"
                    v-bind:editTeamEventSeriesPointsHandler="updateTeamEventSeriesPoints"
                    v-on:editTeamEventSeriesPointsUpdated="updateTeamEventSeriesPointsComplete"
                />
            </template>
        </list>

        <list
            v-bind:items="tournamentStandings"
            v-bind:title="$t('standings')"
            v-bind:waitFor="apiData.standings"
            v-bind:waitForRetry="reloadStandings"
            v-bind:hide="hideElement(3)"
            noShadow
            noLine
            noHorizontalPadding
        >
            <template v-slot:buttons>
                <actionButton
                    variant="secondary"
                    v-bind:permission="permissions.tournaments.standings.generate"
                    v-bind:loadingWhile="apiData.standingsGeneration"
                    loadingThrobber
                    v-on:click="generateStanding"
                >
                    {{ $t('generateStandings') }}
                </actionButton>
                <actionButton
                    variant="success"
                    v-bind:permission="permissions.tournaments.standings.create"
                    v-b-modal.modal-createStanding
                >
                    {{ $t('createStanding') }}
                </actionButton>
            </template>
            <template v-slot="{ item: tournamentStanding }">
                <wrapper
                    v-bind:title="getTournamentStandingTitle(tournamentStanding)"
                    noShadow
                    noLine
                    noPadding
                    noMargin
                    v-if="tournamentStanding"
                >
                    <template v-slot:buttons>
                        <actionButton
                            variant="outline-primary"
                            v-b-modal.modal-editStanding
                            v-bind:permission="permissions.tournaments.standings.update"
                            v-on:click="sendStandingInfo(tournamentStanding)"
                        >
                            {{ $t('editStandings') }}
                        </actionButton>
                        <actionButton
                            variant="outline-danger"
                            v-b-modal="'modal-confirmDeleteStanding-' + tournamentStanding.id"
                            v-bind:permission="permissions.tournaments.standings.delete"
                        >
                            {{ $t('delete') }}
                        </actionButton>
                    </template>
                    <template v-slot>
                        <b-table
                            v-bind:items="tournamentStanding.table"
                            :fields="tableFields"
                            head-variant="light"
                            class="m-0"
                            responsive
                            hover
                        >
                            <template v-slot:head()="data">
                                <span
                                    v-if="data.column !== 'order' && data.column !== 'name'"
                                    v-b-tooltip.hover.top="data.label"
                                >
                                    {{ data.column.toUpperCase() }}
                                </span>
                            </template>
                        </b-table>
                        <confirmModal
                            v-bind:id="'modal-confirmDeleteStanding-' + tournamentStanding.id"
                            v-bind:text="$t('pleaseConfirmDeletion', { name: tournamentStanding.stageName + ': ' + tournamentStanding.name + ', ' + tournamentStanding.typeName })"
                            v-bind:data="tournamentStanding"
                            v-bind:confirmHandler="deleteStandingHandler"
                        />
                    </template>
                </wrapper>
            </template>
        </list>

        <list
            v-bind:items="impactPlayers"
            v-bind:title="$t('impactPlayers')"
            v-bind:waitFor="apiData.impactPlayers"
            v-bind:waitForRetry="reloadImpactPlayers"
            v-bind:hide="hideElement(4)"
            v-if="isSevensTournament"
        >
            <template v-slot:buttons>
                <actionButton
                    class="d-none d-lg-block"
                    variant="outline-primary"
                    v-on:click="printSegment(4)"
                >
                    {{ $t('print') }}
                </actionButton>
            </template>
            <template v-slot:header>
                <playerRow
                    showPositionNumber showTeamCode showTackles showBreaks showOffloads showCarries showTotal
                    isHeadline
                />
            </template>
            <template v-slot="{ item: person, role }">
                <playerRow
                    showPositionNumber showTeamCode showTackles showBreaks showOffloads showCarries showTotal
                    v-bind:person="person"
                    v-bind:role="role"
                />
            </template>
        </list>

        <list
            v-bind:items="tournamentVenues"
            v-bind:title="$tc('venue', 2)"
            v-bind:waitFor="apiData.venues"
            v-bind:waitForRetry="reloadVenues"
            v-bind:hide="hideElement(5)"
        >
            <template v-slot:buttons/>
            <template v-slot:header>
                <venueRow
                    showPlace showDetail
                    isHeadline
                />
            </template>
            <template v-slot="{ item: venue, role }">
                <venueRow
                    showPlace showDetail
                    v-bind:venue="venue"
                    v-bind:role="role"
                />
            </template>
        </list>

        <list
            v-bind:items="tournamentOfficials"
            v-bind:title="$tc('official', 2)"
            v-bind:waitFor="apiData.officials"
            v-bind:waitForRetry="reloadOfficials"
            v-bind:hide="hideElement(6)"
        >
            <template v-slot:buttons>
                <actionButton
                    variant="outline-primary"
                    class="btn-flat"
                    v-bind:permission="permissions.tournaments.officials.create"
                    v-b-modal.modal-addOfficial
                >
                    {{ $t('addOfficial') }}
                </actionButton>
            </template>
            <template v-slot:header>
                <officialRow
                    showPosition showDetail showRemove
                    isHeadline
                />
            </template>
            <template v-slot="{ item: official, role }">
                <officialRow
                    showPosition showDetail showRemove
                    v-bind:person="official"
                    v-bind:role="role"
                    v-bind:removePermission="permissions.tournaments.officials.delete"
                    v-bind:removeHandler="removeOfficial"
                />
            </template>
        </list>

        <disciplinaryList
            v-bind:title="$tc('disciplinary', 2)"
            mainTitle
            v-bind:rowProps="rowProps"
            v-bind:waitFor="apiData.tournamentDisciplinary"
            v-bind:content="tournamentDisciplinary"
            v-bind:showDetail="true"
            v-on:updated="updateDisciplinaryComplete"
            v-bind:rowEditPermission="permissions.tournaments.disciplinaries.update"
            v-bind:rowRemovePermission="permissions.tournaments.disciplinaries.delete"
            v-bind:hide="hideElement(7)"
        >
            <template v-slot:buttons>
                <actionButton
                    variant="outline-primary"
                    class="btn-flat"
                    v-bind:permission="permissions.tournaments.disciplinaries.download"
                    v-on:click="downloadDisciplinary"
                >
                    {{ $t('downloadReport') }}
                </actionButton>
                <actionButton
                    variant="outline-primary"
                    class="btn-flat"
                    v-bind:permission="permissions.tournaments.disciplinaries.create"
                    v-b-modal.modal-addDisciplinary
                >
                    {{ $t('addDisciplinaryAction') }}
                </actionButton>
            </template>
        </disciplinaryList>

        <wrapper v-bind:title="$tc('report', 2)" v-bind:hide="hideElement(8)">
            <simpleRow
                v-bind:text="$t('under19Players')"
                v-bind:moveTo="toUnder19Report"
                openInNewTab
                showMove textLeft normalText
            />
        </wrapper>

        <changeTournamentPublishStatusModal
            id="modal-changePublishTournamentStatus"
            v-bind:title="$t('changeTournamentStatus')"
            v-bind:publishStatus="tournament ? tournament.publishStatus : null"
            v-bind:confirmHandler="updateTournamentPublishStatus"
            v-on:confirmed="updateTournamentPublishStatusComplete"
        />

        <changeTeamStatusModal
            id="modal-changeTeamStatus"
            v-bind:title="$t('editTournamentTeamStatuses')"
            v-bind:tournamentTeams="tournamentTeams ? tournamentTeams : null"
            v-bind:tournamentTeamStatuses="tournamentTeamStatuses"
            v-bind:confirmHandler="updateTournamentTeamStatus"
            v-on:confirmed="updateTournamentTeamStatusComplete"
        />

        <createStandingModal
            id="modal-createStanding"
            v-bind:title="$t('createStanding')"
            v-bind:standingTeams="standingTeams"
            v-bind:sport="getSport"
            v-bind:confirmHandler="createStanding"
            v-on:confirmed="createStandingComplete"
        />

        <editStandingModal
            id="modal-editStanding"
            v-bind:title="$t('editStanding')"
            v-bind:standing="selectedStanding"
            v-bind:standingTeams="standingTeams"
            v-bind:sport="getSport"
            v-bind:confirmHandler="updateStanding"
            v-on:confirmed="updateStandingComplete"
            v-if="selectedStanding"
        />

        <addOfficialModal
            id="modal-addOfficial"
            v-bind:title="$t('addOfficial')"
            v-bind:confirmHandler="addOfficial"
            v-on:confirmed="addOfficialComplete"
        />
        <addEditDisciplinaryModal
            id="modal-addDisciplinary"
            v-bind:title="$t('addDisciplinary')"
            v-bind:tournamentEqId="tournament.eqId"
            v-bind:confirmHandler="addDisciplinary"
            v-on:confirmed="addDisciplinaryComplete"
            v-if="tournament"
        />

        <confirmModal
            v-bind:id="'confirmDeleteDeclaration'"
            v-bind:text="$t('pleaseConfirmDeletion', { name: $t('tournamentDeclaration') })"
            v-bind:data="declaration"
            v-bind:confirmHandler="deleteTournamentDeclarationHandler"
            v-on:confirmed="deleteTournamentDeclarationComplete"
        />
    </div>
</template>

<script>
import base from '@/views/base.vue';
import subNavigation from '@/components/subnavigation.vue';
import detailsHeader from '@/components/detailsHeader.vue';
import constants from '@/constants.js';

import matchRow from '@/components/rows/match.vue';
import teamRow from '@/components/rows/team.vue';
import playerRow from '@/components/rows/player.vue';
import venueRow from '@/components/rows/venue.vue';
import officialRow from '@/components/rows/official.vue';
import simpleRow from '@/components/rows/simple.vue';
import disciplinaryList from '@/components/lists/disciplinary.vue';

import changeTournamentPublishStatusModal from '@/components/modals/changePublishStatus.vue';
import changeTeamStatusModal from '@/components/modals/changeTeamStatus.vue';
import createStandingModal from '@/components/modals/createStanding.vue';
import editStandingModal from '@/components/modals/editStanding.vue';
import addOfficialModal from '@/components/modals/addOfficial.vue';
import addEditDisciplinaryModal from '@/components/modals/addEditDisciplinary.vue';
import confirmModal from '@/components/modals/confirm.vue';

import TournamentModel from '@/models/tournament.js';
import MatchModel from '@/models/match.js';
import TeamModel from '@/models/team.js';
import TournamentStatusModel from '@/models/tournamentStatus.js';
import TournamentStandingModel from '@/models/tournamentStanding.js';
import PlayerModel from '@/models/person/player.js';
import VenueModel from '@/models/venue.js';
import OfficialModel from '@/models/person/official.js';
import DisciplinaryModel from '@/models/disciplinary.js';
import SeriesTournamentModel from '@/models/seriesTournament.js';
import TournamentDeclarationModel from '@/models/declaration/tournament.js';

import { RequestError } from '@/errors.js';

export default {
    name: 'commonTournamentDetail',
    extends: base,
    components: {
        subNavigation,
        detailsHeader,
        matchRow,
        teamRow,
        playerRow,
        simpleRow,
        venueRow,
        officialRow,
        disciplinaryList,
        changeTournamentPublishStatusModal,
        changeTeamStatusModal,
        createStandingModal,
        editStandingModal,
        addOfficialModal,
        addEditDisciplinaryModal,
        confirmModal,
    },
    props: {
        dataTournamentId: {
            type: Number,
            mandatory: true,
            default: null,
        },
    },
    data(){
        this.apiData = {
            tournament: this.loadTournament(),
        };

        return {
            apiData: {
                tournament: this.apiData.tournament,
                tournamentMatches: this.loadTournamentMatches(),
                tournamentTeams: this.loadTournamentTeams(),
                officials: this.loadOfficials(),
                standings: this.loadStandings(),
                standingsGeneration: null,
                impactPlayers: this.loadImpactPlayers(),
                venues: this.loadVenues(),
                tournamentDisciplinary: this.loadTournamentDisciplinary(),
                seriesTournaments: this.loadSeriesTournaments(),
                addRepresentingToMatchesLog: null,
                declarationTemplate: null,
            },
            tournament: null,
            tournamentMatches: [],
            tournamentTeams: [],
            tournamentTeamStatuses: [],
            tournamentOfficials: [],
            tournamentStandings: [],
            standingTeams: [],
            tournamentStandingsTeams: [],
            matchOptions: [],
            tournamentVenues: [],
            impactPlayers: [],
            tournamentDisciplinary: [],
            allPlayers: [],
            seriesTournaments: [],
            showEventSeriesPoints: false,
            isSevensTournament: false,
            matchTeamPersonRepresentings: null,
            declaration: null,
            declarationTOPPreliminary: null,
            declarationTOPMain: null,
            subnavigation: {
                active: 0,
                elements: {
                    0: { label: this.$t('all'), },
                    1: { label: this.$tc('match', 2), },
                    2: { label: this.$tc('team', 2), },
                    3: { label: this.$t('standings'), },
                    4: { label: this.$t('impactPlayers'), hidden: true, },
                    5: { label: this.$tc('venue', 2), },
                    6: { label: this.$tc('official', 2), },
                    7: { label: this.$t('disciplinary'), },
                    8: { label: this.$tc('report', 2), }
                }
            },
            tableFields: [
                { key: 'order', stickyColumn: true, label: '#' },
                { key: 'teamCode', stickyColumn: true, label: 'Team code' },
                { key: 'm', label: 'Matches played' },
                { key: 'w', label: 'Won' },
                { key: 'd', label: 'Draw' },
                { key: 'l', label: 'Lost' },
                { key: 'pf', label: 'Points for' },
                { key: 'pa', label: 'Points against' },
                { key: 'tf', label: 'Tries for' },
                { key: 'ta', label: 'Tries against' },
                { key: 'bp', label: 'Bonues Points' },
                { key: 'pd', label: 'Points difference' },
                { key: 'tb', label: 'Try Bonus' },
                { key: 'pb', label: 'Points Bonus' },
                { key: 'pts', label: 'Points' },
            ],
            selectedStanding: null,
            rowProps: {
                showOrder: true,
                matchReadonly: true,
                teamReadonly: true,
                citingCommissionerReadonly: true,
            },
        };
    },
    computed: {
        detailData(){
            if(!this.tournament){
                return [];
            }

            return [
                {
                    label: this.$tc('sport', 1),
                    content: this.tournament.sport,
                },
                {
                    label: this.$t('mainYear'),
                    content: this.tournament.mainYear,
                },
                {
                    label: this.$t('abbreviation'),
                    content: this.tournament.abbreviation,
                },
                {
                    label: this.$t('status'),
                    content: this.tournament.publishStatus,
                },
                {
                    label: this.$t('winningTeam'),
                    content: this.tournament.winningTeamDisplay,
                },
            ];
        },
        moreDetailData(){
            if(!this.tournament){
                return [];
            }

            if(!this.declaration || (this.declaration && !this.declaration.id)){
                return [];
            }

            return [
                {
                    headline: this.$t('tournamentSquadDeclaration'),
                    items: [
                        {
                            label: this.$t('openingOfTournamentSquadDeclaration'),
                            content: this.declaration.openingDate,
                            type: 'date',
                        },
                        {
                            label: this.$t('deadlineToSubmit'),
                            content: this.declarationTOPMain.squadDeadline || this.declarationTOPMain.preliminarySquadDeadline || null,
                            type: 'date',
                        },
                        {
                            label: this.$t('minimumNumberOfPlayers'),
                            content: this.declarationTOPMain?.squadMinimumNumberOfPlayers || this.declarationTOPPreliminary?.preliminarySquadMinimumNumberOfPlayers || null,
                            type: 'number',
                        },
                        {
                            label: this.$t('maximumNumberOfPlayers'),
                            content: this.declarationTOPMain?.squadMaximumNumberOfPlayers || this.declarationTOPPreliminary?.preliminarySquadMaximumNumberOfPlayers || null,
                            type: 'number',
                        },
                        {
                            label: this.$t('minimumNumberOfManagers'),
                            content: this.declarationTOPMain?.squadMinimumNumberOfManagers || this.declarationTOPPreliminary?.preliminarySquadMinimumNumberOfManagers || null,
                            type: 'number',
                        },
                        {
                            label: this.$t('maximumNumberOfManagers'),
                            content: this.declarationTOPMain?.squadMaximumNumberOfManagers || this.declarationTOPPreliminary?.preliminarySquadMaximumNumberOfManagers || null,
                            type: 'number',
                        },
                        {
                            label: this.$t('minimumNumberOfMedics'),
                            content: this.declarationTOPMain.squadMinimumNumberOfMedics || this.declarationTOPPreliminary?.preliminarySquadMinimumNumberOfMedics || null,
                            type: 'number',
                        },
                        {
                            label: this.$t('maximumNumberOfMedics'),
                            content: this.declarationTOPMain?.squadMaximumNumberOfMedics || this.declarationTOPPreliminary?.preliminarySquadMaximumNumberOfMedics || null,
                            type: 'number',
                        },
                    ],
                },
                {
                    headline: this.$t('personHeadshots'),
                    items: [
                        {
                            label: this.$t('deadlineToSubmit'),
                            content: this.declarationTOPMain?.squadPersonHeadshotsDeadline || this.declarationTOPPreliminary?.preliminarySquadPersonHeadshotsDeadline || null,
                            type: 'date',
                        },
                    ],
                    singleColumn: true,
                },
                {
                    headline: this.$t('passportInformation'),
                    items: [
                        {
                            label: this.$t('deadlineToSubmit'),
                            content: this.declarationTOPMain?.squadPassportInformationDeadline || this.declarationTOPPreliminary?.preliminarySquadPassportInformationDeadline || null,
                            type: 'date',
                        },
                    ],
                    singleColumn: true,
                },
                {
                    headline: this.$t('medicalCertificates'),
                    items: [
                        {
                            label: this.$t('deadlineToSubmit'),
                            content: this.declarationTOPMain?.squadMedicalCertificatesDeadline || this.declarationTOPPreliminary?.preliminarySquadMedicalCertificatesDeadline || null,
                            type: 'date',
                        },
                    ],
                    singleColumn: true,
                },
                {
                    headline: this.$t('biography'),
                    items: [
                        {
                            label: this.$t('deadlineToSubmit'),
                            content: this.declarationTOPMain?.squadBioDeadline || this.declarationTOPPreliminary?.preliminarySquadBioDeadline || null,
                            type: 'date',
                        },
                    ],
                    singleColumn: true,
                },
                {
                    headline: this.$t('participationAgreement'),
                    items: [
                        {
                            label: this.$t('deadlineToSubmit'),
                            content: this.declarationTOPMain?.participationAgreementDeadline || this.declarationTOPPreliminary?.participationAgreementDeadline || null,
                            type: 'date',
                        },
                    ],
                    singleColumn: true,
                },
                {
                    headline: this.$t('teamLogo'),
                    items: [
                        {
                            label: this.$t('deadlineToSubmit'),
                            content: this.declarationTOPMain?.teamLogoDeadline || this.declarationTOPPreliminary?.teamLogoDeadline || null,
                            type: 'date',
                        },
                    ],
                    singleColumn: true,
                },
                {
                    headline: this.$t('nationalAnthem'),
                    items: [
                        {
                            label: this.$t('deadlineToSubmit'),
                            content: this.declarationTOPMain?.nationalAnthemDeadline || this.declarationTOPPreliminary?.nationalAnthemDeadline || null,
                            type: 'date',
                        },
                    ],
                    singleColumn: true,
                },
                {
                    headline: this.$t('medicalInsuranceCover'),
                    items: [
                        {
                            label: this.$t('deadlineToSubmit'),
                            content: this.declarationTOPMain?.medicalInsuranceCoverDeadline || this.declarationTOPPreliminary?.medicalInsuranceCoverDeadline || null,
                            type: 'date',
                        },
                    ],
                    singleColumn: true,
                },
                {
                    headline: this.$t('teamPhoneticGuide'),
                    items: [
                        {
                            label: this.$t('deadlineToSubmit'),
                            content: this.declarationTOPMain?.teamPhoneticGuideDeadline || this.declarationTOPPreliminary?.teamPhoneticGuideDeadline || null,
                            type: 'date',
                        },
                    ],
                    singleColumn: true,
                },
                {
                    headline: this.$t('kitDeclaration'),
                    items: [
                        {
                            label: this.$t('deadlineToSubmit'),
                            content: this.declarationTOPMain?.kitDeadline || this.declarationTOPPreliminary?.kitDeadline || null,
                            type: 'date',
                        },
                    ],
                    singleColumn: true,
                },
            ];
        },
        getSport(){
            return this.tournament?.sport || null;
        },
        hasTournamentDeclaration(){
            return this.declaration?.id;
        },
        toUnder19Report(){
            return { 
                name: 'commonTournamentUnder19Detail', 
                params: { 
                    dataTournamentId: this.dataTournamentId
                } 
            };
        },
    },
    methods: {
        updateTournamentPublishStatus(data) {
            return this.$api.call.tournament.publishStatusTournamentUpdate(this.dataTournamentId, { publishStatus: data });
        },
        updateTournamentPublishStatusComplete(){
            this.reloadTournament();
        },
        loadTournament(){
            return this.$api.call.tournament.tournamentGetById(this.dataTournamentId).then(data => {
                this.tournament = new TournamentModel(data);
                /**
                 * we have to display the impact players for 7s tournaments only (WR-1869)
                 */
                if (this.tournament.isSevens()) {
                    /**
                     * and if the series ID is present and greater than 0 (WR-1869)
                     */
                    if (data.seriesIds.length > 0) {
                        this.isSevensTournament = true;
                    }
                }

                //update subnavigation impactPlayers visibility
                this.$set(this.subnavigation.elements[4], 'hidden', !this.isSevensTournament);

                //reload stuff that require the tournament eqid so they can finally load
                this.reloadTournamentMatches();
                this.reloadTournamentTeams();
                this.reloadTournamentTeamStatuses();
                this.reloadStandings();
                this.reloadImpactPlayers();
                this.reloadVenues();
                this.reloadOfficials();
                this.reloadTournamentDisciplinary();
                this.reloadSeriesTournaments();

                return this.$api.call.termsOfParticipation.tournamentDeclarationByTournamentEqId(this.tournament.eqId).catch(error => {
                    //if none found just use empty data
                    if(error.status === 404 || error.status === 403){
                        return {};
                    }
                    throw error;
                }).then(data => {
                    this.declaration = new TournamentDeclarationModel(data);

                    this.declarationTOPPreliminary = this.declaration.getConfigurationFormModel(TournamentDeclarationModel.steps.preliminarySquad);
                    if (this.declaration.steps.find(s => s.name === TournamentDeclarationModel.steps.squad)){
                        this.declarationTOPMain = this.declaration.getConfigurationFormModel(TournamentDeclarationModel.steps.squad);
                    }
                    else
                    {
                        this.declarationTOPMain = this.declaration.getConfigurationFormModel(TournamentDeclarationModel.steps.main);
                    }

                    return this.declaration;
                });
            });
        },
        reloadTournament(){
            this.apiData.tournament = this.loadTournament();
        },

        loadSeriesTournaments(){
            //request requires the tournament eqid, so until the tournament is loaded we use a promise that never resolves
            if(!this.tournament){
                return new Promise((resolve, reject) => this.apiData.tournament.catch(reject));
            }

            return this.$api.call.tournament.seriesTournamentByTournamentEqId(this.tournament.eqId).then(data => {
                this.seriesTournaments = data.map(item => SeriesTournamentModel.from(item));
                if (this.seriesTournaments.length > 0) {
                    this.showEventSeriesPoints = true;
                }
            }, err => {
                //if request fails with 404 error, interpret as no results found
                if(err instanceof RequestError && err.status === 404){
                    return;
                }
                throw err;
            });
        },
        reloadSeriesTournaments(){
            this.apiData.seriesTournaments = this.loadSeriesTournaments();
        },

        loadTournamentMatches(){
            //request requires the tournament eqid, so until the tournament is loaded we use a promise that never resolves unless the tournament request crashes
            if(!this.tournament){
                return new Promise((resolve, reject) => this.apiData.tournament.catch(reject));
            }

            return this.$api.call.match.matchTournamentsGetByTournamentEqId(this.tournament.eqId).then(tournamentMatches => {
                this.tournamentMatches = tournamentMatches.map(tournamentMatch => new MatchModel(tournamentMatch, this.dataTournamentId));
            });
        },
        reloadTournamentMatches(){
            this.apiData.tournamentMatches = this.loadTournamentMatches();
        },

        loadTournamentTeams(){
            if(!this.tournament){
                return new Promise((resolve, reject) => this.apiData.tournament.catch(reject));
            }

            return this.$api.call.tournament.tournamentTeamsGetAllByTournamentId(this.dataTournamentId).then(result => {
                this.tournamentTeams = ((result || {}).teams || []).map(data => new TeamModel(data));

                this.standingTeams = ((result || {}).teams || []).map(entry => ({
                    id: entry.tournamentTeamId,
                    label: entry.teamName,
                }));
            });
        },
        reloadTournamentTeams(){
            this.apiData.tournamentTeams = this.loadTournamentTeams();
        },

        loadTournamentTeamStatuses(){
            if(!this.tournament){
                return new Promise((resolve, reject) => this.apiData.tournament.catch(reject));
            }

            return this.$api.call.tournament.tournamentTeamStatusGetAll().then(result => {
                this.tournamentTeamStatuses = ((result || {}) || []).map(data => new TournamentStatusModel(data));
            });
        },
        reloadTournamentTeamStatuses(){
            this.apiData.tournamentTeams = this.loadTournamentTeamStatuses();
        },

        removeTournamentTeam(team){
            return this.$api.call.tournament.tournamentTeamDeleteByTournamentTeamId(team.tournamentTeamId).then(result => {
                this.tournamentTeams = [];
                this.reloadTournamentTeams();
            });
        },
        updateTeamEventSeriesPoints(team){
            return this.$api.call.tournament.tournamentTeamUpdatePointsById(team.tournamentTeamId, {
                points: parseInt(team.points)
            });
        },
        updateTeamEventSeriesPointsComplete(){
            this.tournamentTeams = [];
            this.reloadTournamentTeams();
        },
        updateTournamentTeamStatus(data) {
            let dataToSave = [];
            data.forEach(element => {
                if (typeof element.tournamentTeamStatus === 'number') {
                    dataToSave.push({
                        tournamentTeamId: element.tournamentTeamId,
                        teamStatus: this.tournamentTeamStatuses.filter(status => status.id === element.tournamentTeamStatus)[0].name,
                    });
                }
            });

            return this.$api.call.tournament.tournamentTeamStatusesUpdate(this.dataTournamentId, dataToSave);
        },
        updateTournamentTeamStatusComplete(){
            this.reloadTournament();
        },

        loadStandings(){
            //request requires the tournament eqid, so until the tournament is loaded we use a promise that never resolves
            if(!this.tournament){
                return new Promise((resolve, reject) => this.apiData.tournament.catch(reject));
            }

            return this.$api.call.tournament.tournamentStandingsGetByTournamentEqId(this.tournament.eqId)
                .then(response => {
                    if (response) {
                        this.tournamentStandings = response.map(data => new TournamentStandingModel(data));
                    }
                }, err => {
                    //if request fails with 404 error, interpret as no results found
                    if(err instanceof RequestError && err.status === 404){
                        return;
                    }

                    throw err;
                });
        },
        reloadStandings(){
            this.apiData.standings = this.loadStandings();
        },
        generateStanding(){
            this.apiData.standingsGeneration = Promise.all(this.tournamentStandings.map(standing => this.$api.call.tournament.recalculateTournamentStanding(standing.id)));
            this.apiData.standingsGeneration = this.apiData.standingsGeneration.then(() => new Promise(resolve => window.setTimeout(resolve, 1000))); //wait one second for backend to update data before requesting refresh
            this.apiData.standingsGeneration.then(() => this.reloadStandings());
        },
        createStanding(standing) {
            standing.tournamentId = this.dataTournamentId;
            standing.teams.forEach(standingTeam => {
                this.tournamentTeams.forEach(tournamentTeam => {
                    if (tournamentTeam.tournamentTeamId === standingTeam.tournamentTeamId) {
                        standingTeam.teamName = tournamentTeam.teamName;
                    }
                });
            });

            return this.$api.call.tournament.tournamentStandingCreate(standing.toJSON());
        },
        createStandingComplete(){
            this.reloadStandings();
        },
        updateStanding(standing) {
            standing.tournamentId = this.dataTournamentId;
            standing.teams.forEach(standingElement => {
                this.standingTeams.forEach(team => {
                    if (team.id === standingElement.tournamentTeamId) {
                        standingElement.teamName = team.label;
                    }
                });
            });

            // remove deleted teams from Edit-Standings-Modal
            standing.teams = standing.teams.filter(team => team.tournamentTeamId !== null);

            return this.$api.call.tournament.tournamentStandingUpdate(standing.id, standing.toJSON());
        },
        updateStandingComplete(){
            this.reloadStandings();
        },
        deleteStandingHandler(standing){
            return this.$api.call.tournament.tournamentStandingDeleteById(standing.id).then(result => {
                this.tournamentStandings = this.tournamentStandings.filter(function(ele){
                    return ele.id !== standing.id;
                });
            });
        },

        loadImpactPlayers(){
            //request requires the tournament eqid, so until the tournament is loaded we use a promise that never resolves
            if(!this.tournament){
                return new Promise((resolve, reject) => this.apiData.tournament.catch(reject));
            }

            return this.$api.call.match.tournamentImpactPlayerByTournamentEqId(this.tournament.eqId).then(result => {
                this.impactPlayers = (result || []).map(data => new PlayerModel(data));
            });
        },
        reloadImpactPlayers(){
            this.apiData.impactPlayers = this.loadImpactPlayers();
        },

        loadVenues(){
            //request requires the tournament eqid, so until the tournament is loaded we use a promise that never resolves unless the tournament request crashes
            if(!this.tournament){
                return new Promise((resolve, reject) => this.apiData.tournament.catch(reject));
            }

            return this.$api.call.match.tournamentVenues(this.tournament.eqId).then(result => {
                this.tournamentVenues = (result || []).map(data => new VenueModel(data));
            });
        },
        reloadVenues(){
            this.apiData.venues = this.loadVenues();
        },

        loadOfficials(){
            if(!this.tournament){
                return new Promise((resolve, reject) => this.apiData.tournament.catch(reject));
            }

            return this.$api.call.tournament.tournamentOfficialsGetAllByTournamentId(this.dataTournamentId).then(result => {
                this.tournamentOfficials = ((result || {}).tournamentOfficials || []).map(data => new OfficialModel(data));
            });
        },
        reloadOfficials(){
            this.apiData.officials = this.loadOfficials();
        },
        addOfficial(values) {
            const data = {
                positionSourceEqId: values.positionEqId,
            };

            return this.$api.call.tournament.tournamentAddOfficial(this.dataTournamentId, values.personEqId, data);
        },
        addOfficialComplete(){
            this.reloadOfficials();
        },
        removeOfficial(person){
            return this.$api.call.tournament.tournamentOfficialDeleteById(person.id).then(() => {
                this.tournamentOfficials = [];
                this.reloadOfficials();
            });
        },

        loadTournamentDisciplinary(){
            //request requires the tournament eqid, so until the tournament is loaded we use a promise that never resolves unless the tournament request crashes
            if(!this.tournament){
                return new Promise((resolve, reject) => this.apiData.tournament.catch(reject));
            }

            return this.$api.call.match.matchTimelineDisciplinaryByTournamentEqId(this.tournament.eqId).then(result => {
                this.tournamentDisciplinary = (result || []).map(data => new DisciplinaryModel(data));
            });
        },
        reloadTournamentDisciplinary(){
            this.apiData.tournamentDisciplinary = this.loadTournamentDisciplinary();
        },
        addDisciplinary(disciplinary) {
            return this.$api.call.match.matchTimelineDisciplinaryCreateWithTimeline(disciplinary.toTimelineDisciplinaryJSON()).then(data =>
                new DisciplinaryModel(data)
            );
        },
        addDisciplinaryComplete(){
            this.tournamentDisciplinary = [];
            this.reloadTournamentDisciplinary();
        },
        updateDisciplinaryComplete() {
            this.tournamentDisciplinary = [];
            this.reloadTournamentDisciplinary();
        },
        downloadDisciplinary(){
            if(this.tournament){
                this.$api.call.match.matchTimelineDisciplinaryExport({ tournamentEqIds: this.tournament.eqId }, 'tournament-' + this.dataTournamentId + '-disciplinary-export.csv');
            }
        },

        editTournamentLink(id){
            return { name: 'commonTournamentEdit', params: { dataTournamentId: id } };
        },
        hideElement(index){
            if (this.subnavigation) {
                return this.subnavigation.active !== 0 && this.subnavigation.active !== index;
            }
        },
        stepValues(index) {
            if (typeof (this.tournament.declarationSteps[index]) !== 'undefined') {
                return this.tournament.declarationSteps[index];
            }
            return {};
        },
        createTournamentMatchLink() {
            return { name: 'commonTournamentMatchCreate', params: { dataTournamentId: this.dataTournamentId } };
        },
        createTournamentTeamLink() {
            return { name: 'commonTournamentTeamCreate', params: { dataTournamentId: this.dataTournamentId } };
        },
        sendStandingInfo(standing) {
            this.selectedStanding = (standing ? standing.clone() : null);
        },
        getTournamentStandingTitle(tournamentStanding){
            return `
                ${this.$t('pool')}: ${tournamentStanding.name} |
                ${this.$t('standingsType')}: ${tournamentStanding.typeName} |
                ${this.$t('eventStage')}: ${tournamentStanding.stageName}
            `;
        },
        addRepresentingToMatches(){
            this.apiData.addRepresentingToMatchesLog = null;
            let queue = Promise.resolve();

            (this.tournamentTeams || []).forEach(tournamentTeam => {
                queue = queue.then(() => this.$api.call.tournament.tournamentTeamAssignRepresentingToMatches(tournamentTeam.tournamentTeamId));
            });

            //handle queue requests outcome
            this.apiData.addRepresentingToMatchesLog = queue.then(() => {
                //all done
            }).catch(error => {
                if(this.$log){
                    this.$log.warn('add tournament team player representings to match team player representings failed', error);
                }

                //show validation errors
                this.error = error;
            });
        },
        deleteTournamentDeclarationHandler(data){
            return this.$api.call.termsOfParticipation.declarationDeleteById(constants.declarationTypes.tournament, data?.id);
        },
        deleteTournamentDeclarationComplete(){
            this.declaration = null;
            this.declarationTOPPreliminary = null;
            this.declarationTOPMain = null;

            this.reloadTournament();
        },
    },
    created(){
        this.disciplinaryListFilter = [
            {
                id: 'actionTypeName',
                type: 'vselectActionType',
                placeholder: this.$t('filterByCard')
            },
            {
                id: 'team',
                type: 'vselect',
                options: this.tournamentTeams,
                placeholder: this.$t('filterByTeam')
            },
            {
                id: 'law',
                type: 'vselectLaw',
                placeholder: this.$t('filterByLaw')
            },
            {
                id: 'playerName',
                type: 'vselect',
                options: this.players,
                placeholder: this.$t('filterByPlayerName')
            },
        ];
    },
};
</script>
