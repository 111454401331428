<template>
    <div class="pageContent">
        <header>
            <div class="d-flex flex-row justify-content-end">
                <actionButton
                    size="sm"
                    variant="danger"
                    v-b-modal.modal-deleteAllFailedIngestion
                >
                    Delete All
                </actionButton>
            </div>
        </header>
        <unmappedList
            v-bind:title="$t('failedDataIngestion')"
            mainTitle
            v-bind:rowProps="rowProps"
            v-bind:rowRemovePermission="permissions.unmapped.delete"
        />
        <confirmModal
            v-bind:id="'modal-deleteAllFailedIngestion'"
            v-bind:text="$t('pleaseConfirmDeletionFailedIngestion')"
            v-bind:confirmHandler="deleteAll"
        />
    </div>
</template>

<script>
import base from '@/views/base.vue';
import unmappedList from '@/components/lists/administration/unmapped.vue';
import confirmModal from '@/components/modals/confirm.vue';

export default {
    name: 'administrationUnmappedIndex',
    extends: base,
    components: {
        unmappedList,
        confirmModal
    },
    data(){
        return {
            rowProps: {
                showDelete: true,
            },
            showDeleteAll: false,
            name: 'all failed data ingeston records'
        };
    },
    methods: {
        toggleModal() {
            this.showDeleteAll = true;
        },
        deleteAll() {
            return this.$api.call.mapping.unmappedDeleteAll().then(() => {
                this.$router.go(this.$router.currentRoute);
            });
        }
    }
};
</script>
