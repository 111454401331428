<template>
    <div class="pageContent">
        <rankingList
            v-bind:title="sportId === constants.rankingTypes.mens ? $t('mensRanking') : $t('womensRanking')"
            mainTitle
            v-bind:rowProps="rowProps"
            v-bind:sport="sportId"
            v-on:runLoaded="rankingRun = $event"
            v-on:runMatchesLoaded="runMatches = $event"
            v-on:loadingData="runMatches = []"
            ref="list"
        >
            <template v-slot:buttons>
                <actionButton
                    variant="success"
                    v-bind:permission="permissions.rankings.generate"
                    v-bind:loadingWhile="apiData.runLiveGeneration"
                    loadingThrobber
                    v-on:click="generateLiveRun"
                >
                    {{ $t('generateLiveRun') }}
                </actionButton>
                <actionButton
                    v-bind:to="{ name: 'administrationRankingPredictor' }"
                >
                    {{ $t('predictor') }}
                </actionButton>
                <actionButton
                    v-bind:to="{ name: 'administrationRankingInactivity' }"
                >
                    {{ $t('inactivityReport') }}
                </actionButton>
                <actionButton
                    v-bind:to="{ name: 'administrationRankingPenaltiesIndex' }"
                >
                    {{ $tc('penalty', 2) }}
                </actionButton>
                <actionButton
                    v-bind:to="{ name: 'administrationRankingCalculationsIndex' }"
                >
                    {{ $t('adjustCalculations') }}
                </actionButton>
                <actionButton
                    variant="success"
                    v-bind:permission="permissions.rankings.generate"
                    v-bind:loadingWhile="apiData.runGeneration"
                    loadingThrobber
                    v-on:click="generateRun"
                >
                    {{ $t('generateRun') }}
                </actionButton>
                <actionButton
                    variant="danger"
                    v-bind:permission="permissions.rankings.delete"
                    v-b-modal.confirmRemoveRankingRun
                >
                    {{ $t('delete') }}
                </actionButton>
            </template>
            <template v-slot:header>
                <actionButton
                    class="mb-3 d-flex"
                    variant="outline-primary"
                    v-b-toggle.runMatches
                    v-if="runMatchesItems.length"
                >
                    <span>{{ $t('showMatches') }}</span>
                    <icon v-bind:type="runMatchesOpen ? 'up' : 'down'" class="ml-1"/>
                </actionButton>
                <b-collapse
                    v-model="runMatchesOpen"
                    id="runMatches"
                    class="overflow-auto mb-3"
                    v-if="runMatchesItems.length"
                >
                    <b-table
                        v-bind:items="runMatchesItems"
                        v-bind:fields="runMatchesFields"
                        class="runMatchTable mt-n2 mb-2"
                        head-variant="light"
                        hover
                        v-if="runMatchesItems.length"
                    >
                        <template #cell(home)="data">
                            <icon v-bind:type="data.value ? 'confirm-circle-full' : 'cancel'"/>
                        </template>
                        <template #cell(link)="data">
                            <actionButton
                                variant="icon"
                                v-bind:to="{ name: 'commonMatchDetail', params: { dataMatchId: data.item.matchId } }"
                                openInNewTab
                                v-if="data.item.matchId"
                            >
                                <icon type="view"/>
                            </actionButton>
                        </template>
                    </b-table>
                </b-collapse>
            </template>
        </rankingList>

        <confirmModal
            id="confirmRemoveRankingRun"
            v-bind:text="$t('areYouSureToDelete', { type: rankingRun.effectiveDate })"
            v-bind:confirmHandler="deleteRun"
            v-on:confirmed="reloadList"
            v-if="rankingRun"
        />
    </div>
</template>

<script>
import rankingBase from '@/views/administration/rankings/base.vue';
import rankingList from '@/components/lists/administration/ranking.vue';
import confirmModal from '@/components/modals/confirm.vue';
import icon from '@/components/icon.vue';

export default {
    name: 'administrationRankingIndex',
    extends: rankingBase,
    components: {
        rankingList,
        confirmModal,
        icon,
    },
    data(){
        return {
            apiData: {
                runGeneration: null,
                runLiveGeneration: null,
            },
            rankingRun: null,
            runMatches: [],
            runMatchesOpen: false,
            liveRun: false,
            rowProps: {},
        }
    },
    computed: {
        runMatchesFields(){
            return [
                {
                    key: 'date',
                    label: this.$t('date'),
                },
                {
                    key: 'team',
                    label: this.$tc('team', 1),
                },
                {
                    key: 'home',
                    label: this.$t('home'),
                },
                {
                    key: 'score',
                    label: this.$t('score'),
                },
                {
                    key: 'result',
                    label: this.$t('result'),
                },
                {
                    key: 'ptsBefore',
                    label: this.$t('ptsBefore'),
                },
                {
                    key: 'ptsAfter',
                    label: this.$t('ptsAfter'),
                },
                {
                    key: 'eventMultiplier',
                    label: this.$t('eventMultiplierAbbr'),
                },
                {
                    key: 'winMultiplier',
                    label: this.$t('winMultiplierAbbr'),
                },
                {
                    key: 'link',
                    label: '',
                    class: 'd-print-none',
                },
            ];
        },
        runMatchesItems(){
            const items = [];

            this.runMatches.forEach(runMatch => {
                items.push({
                    date: this.$fd(runMatch.match.kickOff, 'dateTime'),
                    team: runMatch.team1Code,
                    home: runMatch.team1IsAtHome,
                    score: runMatch.team1Score,
                    result: runMatch.team1Result,
                    ptsBefore: runMatch.team1PointsBefore,
                    ptsAfter: runMatch.team1Points,
                    eventMultiplier: runMatch.matchMultiplier,
                    winMultiplier: runMatch.winMultiplier,
                    matchId: runMatch.match.id,
                });

                items.push({
                    date: runMatch.match.tournaments.map(tournament => tournament.label()).join(', '),
                    team: runMatch.team2Code,
                    home: runMatch.team2IsAtHome,
                    score: runMatch.team2Score,
                    result: runMatch.team2Result,
                    ptsBefore: runMatch.team2PointsBefore,
                    ptsAfter: runMatch.team2Points,
                });
            });

            return items;
        },
    },
    methods: {
        generateRun(){
            this.apiData.runGeneration = this.$api.call.rankings.runGenerateCalculate({
                sport: this.sportId,
                isLive: false,
            }).then(data => {
                this.reloadList();
            });
        },
        generateLiveRun(){
            this.apiData.runLiveGeneration = this.$api.call.rankings.runGenerateCalculate({
                sport: this.sportId,
                isLive: true,
            }).then(data => {
                this.reloadList();
            });
        },
        deleteRun(){
            return this.$api.call.rankings.runDeleteById(this.rankingRun.id);
        },
        reloadList(){
            if(!this.$refs.list) {
                return;
            }

            this.$refs.list.reload(true);
        }
    },
}
</script>
